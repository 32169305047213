import { types, cast, Instance } from 'mobx-state-tree';

const UserModel = types
  .model('UserModel', {
    clientId: types.string,
    playerId: types.number,
    jwt: types.string,
    energy: types.number,
    dcc: types.number,
    fsh: types.number,
    pendingReward: types.number
  })
  .actions((self) => {
    // GETTER
    const getClientId = (): string => {
      return self.clientId;
    };

    const getJWT = (): string => {
      return self.jwt;
    };

    // SETTER
    const setClientId = (clientId: string) => {
      self.clientId = clientId;
    };

    const setPlayerId = (playerId: number) => {
      self.playerId = playerId;
    };

    const setJWT = (jwt: string) => {
      self.jwt = jwt;
    };

    const setEnergy = (energy: number) => {
      self.energy = energy;
    };

    const setDcc = (dcc: number) => {
      self.dcc = dcc;
    };

    const setFsh = (fsh: number) => {
      self.fsh = fsh;
    };

    const setPendingReward = (reward: number) => {
      self.pendingReward = reward;
    };

    return {
      getClientId,
      getJWT,

      setClientId,
      setPlayerId,
      setJWT,
      setEnergy,
      setDcc,
      setFsh,
      setPendingReward
    };
  })
  .create({
    clientId: '',
    playerId: 0,
    jwt: '',
    energy: 0,
    dcc: 0,
    fsh: 0,
    pendingReward: 0
  });

export default UserModel;
