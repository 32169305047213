import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  CircularProgress,
  CircularProgressLabel,
  Text,
  Button,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { fetchTokens } from 'common/utils/tokenApi/fetchTokens';
import { useStores } from 'stores';
import web3 from 'common/utils/tokenApi/web3';
import { getUserInfo } from 'client';
import TokenDialog from '../TokenDialog';

const StatusBox = observer(() => {
  const [openTokenDialog, setOpenTokenDialog] = useState(false);
  const { UserStore } = useStores();

  async function refreshData() {
    const tokens = await fetchTokens();
    // todo: set dcc and fsh tokens
    tokens?.forEach((token) => {
      console.log('token balance', token.balance);

      let tokenBalance = parseFloat(
        token.balance.slice(0, token.balance.length - 18),
      );
      console.log(web3.utils.fromWei(token.balance));
      tokenBalance = Math.floor(tokenBalance * 100) / 100 || 0;

      let tokenPendingReward = parseFloat(
        web3.utils.fromWei(token.pendingReward),
      );
      tokenPendingReward = Math.floor(tokenPendingReward * 100) / 100;

      if (token.symbol === 'DCC') {
        UserStore.setDcc(tokenBalance);
      } else if (token.symbol === 'FSH') {
        UserStore.setFsh(tokenBalance);
        UserStore.setPendingReward(tokenPendingReward);
      }
    });

    // return tokens;
  }

  useEffect(() => {
    refreshData();
    getUserInfo(UserStore.clientId).then((res: any) => {
      const { message } = res;
      UserStore.setPlayerId(message.playerId);
      UserStore.setEnergy(message.energy);
    });
  }, []);

  return (
    <Button
      variant="unstyled"
      borderRadius="10px"
      backgroundColor="#777DED"
      display="flex"
      width="260px"
      height="100px"
      onClick={() => {
        setOpenTokenDialog(true);
      }}
    >
      <TokenDialog
        open={openTokenDialog}
        onCloseDialog={() => {
          setOpenTokenDialog(false);
        }}
        dccToken={UserStore.dcc}
        fshToken={UserStore.fsh}
        refreshData={refreshData}
        pendingReward={UserStore.pendingReward}
      />
      <CircularProgress
        value={UserStore.energy}
        color="green.400"
        size="90"
        ml="12px"
        mt="6px"
      >
        <CircularProgressLabel>
          <Text color="white" fontWeight="medium">
            {UserStore.energy} E
          </Text>
        </CircularProgressLabel>
      </CircularProgress>
      <Flex
        flexDirection="column"
        ml="25px"
        justifyContent="center"
        alignItems="end"
        mr="10px"
      >
        <Text color="white" fontWeight="medium" fontSize="24px">
          {UserStore.dcc.toFixed(2)} DCC
        </Text>
        <Text color="white" fontWeight="medium" fontSize="24px">
          {UserStore.fsh.toFixed(2)} FSH
        </Text>
      </Flex>
    </Button>
  );
});

export default StatusBox;
