import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  GridItem,
  Image,
  Spacer,
  Text,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { useBoolean } from '@chakra-ui/hooks';
import { observer } from 'mobx-react-lite';
import ConnectWalletButton from 'modules/components/ConnectWalletButton';
import { parseChesses } from 'common/utils/Chess';
import { chesses, chesses2 } from 'common/placeholders/Chesses';
import GameStateDialog from 'modules/components/GameStateDialog';
import ChessCard from 'modules/components/ChessCard';
import AcceptMatchDialog from 'modules/components/AcceptMatchDialog';
import { mqttClient } from 'common/utils/MqttService';
import { GameState } from 'stores/Game';
import { getChessPieces } from 'client';
import { useStores } from '../../../stores';
import { ChessClass, ChessSlot, ChessType } from '../../types/Chess';

const Home: React.FC<any> = observer((): JSX.Element => {
  const { UserStore, GameStore } = useStores();
  const history = useHistory();
  const toast = useToast();

  const [isFindingMatch, setFindingMatch] = useBoolean();
  const [isLoadingTeam, setLoadingTeam] = useBoolean(true);
  const [isAccepted, setAccepted] = useBoolean(false);

  const acceptanceHandler = () => {
    // GameStore.setOpponent('0x888888888888888888888888888888');
    // GameStore.setOpponentELO(1200);
    // GameStore.setOpponentChessResponse(chesses2)
    setFindingMatch.off();
    GameStore.setGameState(GameState.ACCEPTANCE);
  };

  const roomReady = ({
    roomId,
    players,
  }: {
    roomId: string;
    players: string[];
  }) => {
    GameStore.setGameState(GameState.ROOM_READY);
    GameStore.setGameId(roomId);
    console.log('call room ready');

    if (players[1] === UserStore.getClientId()) {
      // GameStore.setMyChesses(chesses2, 0);
      GameStore.setIsPlayer2(true);
      GameStore.setOpponent(players[0]);
    } else {
      // GameStore.setMyChesses(chesses, 0);
      GameStore.setOpponent(players[1]);
    }

    history.push('/game');
  };

  useEffect(() => {
    setLoadingTeam.on();
    getChessPieces(UserStore.clientId).then((res: any) => {
      console.log('res:', res);
      const parsedChesses = parseChesses(res.items);
      GameStore.setMyChesses(parsedChesses);
      setLoadingTeam.off();
    });

    // mqttClient.subscribe('/dechess/sub/room-ready/#');
    mqttClient.on('message', (topic, message) => {
      const data = JSON.parse(message.toString());
      console.log('at room ready', topic, data);

      if (
        topic.includes('match-making') &&
        topic.includes(UserStore.clientId)
      ) {
        switch (data.action) {
          case 'CHECK_ONLINE':
            console.log('check online publish');
            mqttClient.publish(
              `/dechess/pub/match-making/${UserStore.clientId}`,
              JSON.stringify({
                action: 'CHECK_ONLINE',
                isOnline: true,
                token: UserStore.jwt,
              }),
            );
            break;
          case 'MATCH_FOUND': {
            const { playerData } = data;
            const opponentData =
              playerData[0].public_address === UserStore.clientId
                ? playerData[1]
                : playerData[0];
            GameStore.setOpponent(opponentData.public_address);
            GameStore.setOpponentELO(opponentData.elo);
            acceptanceHandler();
            break;
          }
          case 'ACCEPTING': {
            console.log('accepting data:', data);
            const { roomId, players, playerStatus } = data;
            if (playerStatus === 'READY') {
              mqttClient.unsubscribe(
                `/dechess/sub/match-making/${UserStore.clientId}`,
              );
              setAccepted.off();
              roomReady({ roomId, players });
            }
            if (playerStatus === 'CONTINUE_FINDING') {
              setFindingMatch.on();
              toast({
                title: `Opponent declined match :(`,
                status: 'warning',
                isClosable: true,
              });
              setAccepted.off();
              GameStore.setGameState(GameState.CONNECTING);
            }
            break;
          }
          case 'DISCONNECT':
            console.log('disconnect data:', data);
            break;
          default:
            break;
        }
      }
    });

    return () => {
      mqttClient.unsubscribe(`/dechess/sub/match-making/${UserStore.clientId}`);
      mqttClient.removeAllListeners('message');
      // mqttClient.publish(
      //   `/dechess/pub/match-making/${UserStore.clientId}`,
      //   JSON.stringify({
      //     action: 'ACCEPTING',
      //     accept: false,
      //     token: UserStore.jwt,
      //   }),
      // );
    };
  }, []);

  const onAccept = () => {
    setAccepted.on();
    mqttClient.publish(
      `/dechess/pub/match-making/${UserStore.clientId}`,
      JSON.stringify({
        action: 'ACCEPTING',
        accept: true,
        token: UserStore.jwt,
      }),
    );
    toast({
      title: `You accept a match!`,
      status: 'success',
      isClosable: true,
    });
  };

  const onPlay = () => {
    setFindingMatch.on();
    // GameStore.reset();

    GameStore.setGameState(GameState.CONNECTING);
    mqttClient.publish(
      `/dechess/pub/connect/${UserStore.clientId}`,
      `{"public_address": "${UserStore.clientId}", "token": "${UserStore.jwt}"}`,
    );
    mqttClient.subscribe(`/dechess/sub/match-making/${UserStore.clientId}`);
    // setTimeout(() => {
    //   acceptanceHandler();
    // }, 3000);
  };

  const onDeclineMatch = () => {
    GameStore.setGameState();
    if (!isAccepted) {
      toast({
        title: `You declined a match!`,
        status: 'warning',
        isClosable: true,
      });
      mqttClient.publish(
        `/dechess/pub/match-making/${UserStore.clientId}`,
        JSON.stringify({
          action: 'ACCEPTING',
          accept: false,
          token: UserStore.jwt,
        }),
      );
      mqttClient.unsubscribe(`/dechess/sub/match-making/${UserStore.clientId}`);
    }
    setAccepted.off();
  };
  // Need to fetch the real chess team later
  const renderTeam = (): Array<JSX.Element> => {
    return [
      ChessSlot.KING,
      ChessSlot.ROOK,
      ChessSlot.KNIGHT,
      ChessSlot.BISHOP_1,
      ChessSlot.BISHOP_2,
    ].map((chessSlot) => {
      const chess = GameStore.myChesses?.units.find(
        (c) => c.slot === chessSlot,
      ) as ChessType;
      return <ChessCard chess={chess} slot={chessSlot} />;
    });
  };
  return (
    <Box backgroundColor="#777DED" minHeight="100%" minWidth="100%">
      <Box
        paddingY="12"
        paddingX={{ sm: '12', lg: '24' }}
        flexDirection="column"
        overflow="hidden"
      >
        <Box
          maxWidth={{ sm: 'md', md: '2xl', lg: '80%' }}
          marginX={{ sm: 'auto' }}
          width={{ sm: 'full' }}
        >
          <Flex flexDirection="row" alignItems="center">
            <Text
              color="white"
              fontWeight="bold"
              fontSize="48px"
              textShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
            >
              Your team
            </Text>
            <Link to="/manage">
              <Button
                variant="solid"
                size="solidxl"
                rounded="lg"
                marginTop="10px"
                marginLeft="40px"
                backgroundImage="linear-gradient(225deg, #68DBF2 0.01%, #509CF5 100%)"
              >
                Manage
              </Button>
            </Link>
          </Flex>
          {isLoadingTeam ? (
            <Flex justifyContent="center">
              <Spinner color="white" size="xl" thickness="4px" mt="20px" />
            </Flex>
          ) : (
            <SimpleGrid marginTop="20px" spacing="20px" minChildWidth="300px">
              {renderTeam()}
            </SimpleGrid>
          )}
          <Text
            color="white"
            fontWeight="bold"
            fontSize="48px"
            textShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
            marginTop="40px"
          >
            User info
          </Text>
          <Flex width="100%" alignItems="center" marginTop="20px">
            <Box
              backgroundColor="white"
              boxShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
              rounded="2xl"
              width="60%"
            >
              <Box margin="40px">
                <Text fontWeight="medium" fontSize="18">
                  Wallet address : {UserStore.clientId}
                </Text>
                <Text fontWeight="medium" fontSize="18">
                  ELO 1200
                </Text>
              </Box>
            </Box>
            <Flex
              align="center"
              alignItems="center"
              justify="center"
              justifyItems="center"
              marginLeft="40px"
              width="40%"
            >
              <Button
                disabled={GameStore.myChesses?.units.length !== 5}
                variant="roundsolid"
                fontSize="40px"
                fontWeight="bold"
                height="100px"
                backgroundImage="linear-gradient(225deg, #24E795 0%, #42CAE8 99.99%, #67E9F1 100%);"
                onClick={onPlay}
              >
                <Flex marginX="100px">
                  <Image src="/images/home_play_button_image.png" />
                  <Text marginLeft="40px">Play</Text>
                </Flex>
              </Button>
              <GameStateDialog
                open={isFindingMatch}
                onSetClose={() => {
                  setFindingMatch.off();
                  GameStore.setGameState();
                  mqttClient.publish(
                    `/dechess/pub/match-making/${UserStore.clientId}`,
                    JSON.stringify({
                      action: 'DISCONNECT',
                      token: UserStore.jwt,
                    }),
                  );
                }}
                guidingText="Finding match..."
                isLoad
              />
              <AcceptMatchDialog
                open={GameStore.gameState === GameState.ACCEPTANCE}
                accepted={isAccepted}
                onSetClose={() => {
                  onDeclineMatch();
                }}
                onAccept={() => {
                  onAccept();
                }}
              />
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Image width="100%" src="/images/static_tree_background.png" />
    </Box>
  );
});

export default Home;
