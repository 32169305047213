import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Flex,
  Image,
  Input,
  Spacer,
  Text,
  useInterval,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import ERC20Token from 'common/utils/tokenApi/ERC20Token';
import { DechessStaking } from 'common/contracts/DechessStaking';
import { getAccount } from 'common/blockchain';
import { ethers } from 'ethers';
import { number } from 'mobx-state-tree/dist/internal';

const StakingContract: string = process.env.REACT_APP_STAKING_CONTRACT as string;

const Staking: React.FC<any> = observer((): JSX.Element => {
  const [apr, setApr] = useState(50);
  // const [totalReward, setTotalReward] = useState(2500000);
  const [poolLimit, setPoolLimit] = useState(0);
  const [totalStaked, setTotalStaked] = useState(2500000);
  const [myStaked, setMyStaked] = useState(2500);
  const [reward, setReward] = useState(120);

  const [stakeAmount, setStakeAmount] = useState('');

  async function refreshData() {
    const account = await getAccount();
    const contract = new DechessStaking(StakingContract);
    const promises = [];

    promises.push(
      contract
        .globalPoolLimit()
        .then((x) => setPoolLimit(parseInt(ethers.utils.formatEther(x), 10))),
    );

    promises.push(
      contract
        .totalStaked()
        .then((x) => setTotalStaked(parseInt(ethers.utils.formatEther(x), 10))),
    );

    promises.push(
      contract
        .userStaked(account)
        .then((x) => setMyStaked(parseInt(ethers.utils.formatEther(x), 10))),
    );

    promises.push(
      contract
        .pendingReward(account)
        .then((x) => setReward(parseFloat(ethers.utils.formatEther(x)))),
    );
  }

  async function stake() {
    const account = await getAccount();
    const amount = ethers.utils.parseEther(stakeAmount);
    await new ERC20Token(
      account,
      process.env.REACT_APP_DCC_ADDRESS as string,
    ).approveSmart(StakingContract, amount.toString());
    const contract = new DechessStaking(StakingContract);
    await contract.deposit(amount);
    refreshData();
  }

  async function unstake() {
    const contract = new DechessStaking(StakingContract);
    await contract.withdraw(ethers.utils.parseEther(stakeAmount));
    refreshData();
  }

  async function harvest() {
    const contract = new DechessStaking(StakingContract);
    await contract.harvest();
    refreshData();
  }

  async function updateApr() {
    const contract = new DechessStaking(StakingContract);
    const rewardPerBlock = parseFloat(
      ethers.utils.formatEther(await contract.rewardPerBlock()),
    );
    const blockPerYear = 3600 * 24 * 365;
    const totalReward = rewardPerBlock * blockPerYear;

    setApr((totalReward / totalStaked) * 100);
  }

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    updateApr();
  }, [totalStaked]);

  useInterval(() => {
    refreshData();
  }, 12000);

  return (
    <Flex
      backgroundColor="#777DED"
      minHeight="100%"
      minWidth="100%"
      align="center"
      alignItems="center"
      justify="center"
      justifyContent="center"
      flexDirection="column"
      overflow="hidden"
    >
      <Text
        color="white"
        fontWeight="bold"
        fontSize="48px"
        textShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
        mb="20px"
      >
        Staking
      </Text>
      <Flex
        w="600px"
        background="white"
        boxShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
        rounded="2xl"
        flexDir="column"
        alignItems="center"
        overflow="hidden"
        mb="40px"
      >
        <Flex
          background="linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%)"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Text fontWeight="bold" fontSize="36px" m="10px" color="white">
            DCC
          </Text>
        </Flex>
        <Flex width="100%" p="30px" alignItems="center" flexDir="column">
          <Flex
            width="100%"
            justifyContent="space-between"
            borderBottomColor="gray.500"
            mb="20px"
          >
            <Text fontWeight="medium" fontSize="24px">
              APR
            </Text>
            <Text fontWeight="medium" fontSize="24px">
              {apr.toFixed(2)}%
            </Text>
          </Flex>
          <Divider />
          {poolLimit > 0 && (
            <Flex
              width="100%"
              justifyContent="space-between"
              borderBottomColor="gray.500"
              my="20px"
            >
              <Text fontWeight="medium" fontSize="24px">
                Pool Limit
              </Text>
              <Text fontWeight="medium" fontSize="24px">
                {`${poolLimit} DCC`}
              </Text>
            </Flex>
          )}
          {/* <Flex
            width="100%"
            justifyContent="space-between"
            borderBottomColor="gray.500"
            my="20px"
          >
            <Text fontWeight="medium" fontSize="24px">
              Total reward
            </Text>
            <Text fontWeight="medium" fontSize="24px">
              {`${totalReward} DCC`}
            </Text>
          </Flex> */}
          <Divider />
          <Flex
            width="100%"
            justifyContent="space-between"
            borderBottomColor="gray.500"
            my="20px"
          >
            <Text fontWeight="medium" fontSize="24px">
              Total staked
            </Text>
            <Text fontWeight="medium" fontSize="24px">
              {`${totalStaked} DCC`}
            </Text>
          </Flex>
          <Divider />
          <Flex
            width="100%"
            justifyContent="space-between"
            borderBottomColor="gray.500"
            my="20px"
          >
            <Text fontWeight="medium" fontSize="24px">
              My Staked
            </Text>
            <Text fontWeight="medium" fontSize="24px">
              {`${myStaked} DCC`}
            </Text>
          </Flex>
          <Divider />
          <Flex
            width="100%"
            justifyContent="space-between"
            borderBottomColor="gray.500"
            my="20px"
          >
            <Text fontWeight="medium" fontSize="24px">
              Reward
            </Text>
            <Button
              variant="solid"
              size="solidxl"
              background="linear-gradient(225deg, #68DBF2 0.01%, #509CF5 100%)"
              onClick={harvest}
            >
              {`${reward.toFixed(2)} DCC`}
            </Button>
          </Flex>
          <Divider />
          <Flex width="100%" justifyContent="space-between" my="20px">
            <Input
              placeholder="Enter amount to stake (DCC)"
              mr="10px"
              size="lg"
              value={stakeAmount}
              onChange={(e) => setStakeAmount(e.target.value)}
            />
            <Button
              variant="solid"
              size="solidxl"
              style={{ marginRight: 10 }}
              onClick={stake}
              disabled={!(!Number.isNaN(+stakeAmount) && +stakeAmount > 0)}
            >
              Stake
            </Button>
            <Button
              variant="solid"
              size="solidxl"
              onClick={unstake}
              disabled={!(!Number.isNaN(+stakeAmount) && +stakeAmount > 0)}
            >
              Unstake
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Image width="100%" src="/images/static_tree_background.png" />
    </Flex>
  );
});

export default Staking;
