import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';

type Props = {
  open?: boolean;
  title: string;
  body: string;
  onYes: () => void;
  onCancel?: () => void;
};

const ConfirmDialog = ({
  open = false,
  title,
  body,
  onYes,
  onCancel,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (open) onOpen();
    else onClose();
  }, [open]);

  const onCloseDialog = () => {
    onCancel?.();
    onClose();
  };
  return (
    <>
      <Modal
        isCentered
        onClose={onCloseDialog}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{body}</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={() => onYes()}>
              Yes
            </Button>
            <Button variant="ghost" onClick={() => onCloseDialog()}>
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmDialog;
