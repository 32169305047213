import { useDisclosure } from '@chakra-ui/hooks';
import { Image } from '@chakra-ui/image';
import { Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/modal';
import {
  Button,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  Flex,
  Input,
  Spinner,
} from '@chakra-ui/react';
import {
  callTokenApi,
  checkTxHashPolling,
  recoverTxWithdrawSignature,
} from 'common/utils/tokenApi/api';
import ERC20Token from 'common/utils/tokenApi/ERC20Token';
import TokenDepositer from 'common/utils/tokenApi/TokenDepositer';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useStores } from 'stores';

function TokenDialog({
  open = false,
  onCloseDialog,
  dccToken,
  fshToken,
  refreshData,
  pendingReward,
}: {
  open?: boolean;
  onCloseDialog?: () => void;
  dccToken: number;
  fshToken: number;
  refreshData: any;
  pendingReward: number;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [processing, setProcessing] = useState(false);
  const [processingTxHash, setProcessingTxHash] = useState('');

  const [dccDepositAmount, setDccDepositAmount] = useState('');
  const [fshDepositAmount, setFshDepositAmount] = useState('');
  const { UserStore } = useStores();

  const date = new Date();

  async function deposit(tokenAddress: string, amount: string | number) {
    try {
      setProcessing(true);

      await new ERC20Token(UserStore.getClientId(), tokenAddress).approve(
        process.env.REACT_APP_TOKEN_DEPOSITER_CONTRACT as string,
      );

      const tx = await new TokenDepositer(
        UserStore.getClientId(),
        UserStore.getClientId(),
      ).depositERC20(
        tokenAddress,
        ethers.utils.parseEther(amount.toString()).toString(),
      );

      setProcessingTxHash(tx.transactionHash);
      await checkTxHashPolling(tx.transactionHash);
    } finally {
      setProcessing(false);
      setProcessingTxHash('');
      refreshData();
    }
  }

  async function withdraw(tokenAddress: string, amount: string | number) {
    console.log(UserStore.getClientId());

    await callTokenApi({
      action: 'txWithdraw',
      args: {
        tokenAddress,
        tokenId: 0,
        amount: ethers.utils.parseEther(amount.toString()).toString(),
      },
      before: async () => setProcessing(true),
      then: async (data) => {
        console.log('XXX');
        const meta = recoverTxWithdrawSignature(data);
        console.log(meta);
        const tx = await new TokenDepositer(
          UserStore.getClientId(),
          UserStore.getClientId(),
        ).executeMetaTransaction(
          meta.functionSignature,
          meta.r,
          meta.s,
          meta.v,
        );

        console.log('YYY');

        setProcessingTxHash(tx.transactionHash);
        await checkTxHashPolling(tx.transactionHash);
      },
      finish: async (status, data) => {
        setProcessing(false);
        setProcessingTxHash('');
        refreshData();
      },
    });
  }

  async function claimReward(tokenAddress: string) {
    console.log(UserStore.getClientId());

    await callTokenApi({
      action: 'txHarvestReward',
      args: {
        walletAddress: UserStore.clientId,
        tokenAddress,
        tokenId: 0,
      },
      before: async () => setProcessing(true),
      then: async (data) => {
        console.log('YYY');
      },
      finish: async (status, data) => {
        setProcessing(false);
        setProcessingTxHash('');
        refreshData();
      },
    });
  }

  useEffect(() => {
    if (open) onOpen();
    else onClose();
  }, [open]);

  useEffect(() => {
    refreshData();
  }, [UserStore.getClientId()]);

  return (
    <>
      <Modal
        isCentered
        onClose={() => {
          onCloseDialog?.();
          onClose();
        }}
        isOpen={isOpen}
        motionPreset="scale"
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            minHeight="500px"
            display="flex"
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
          >
            <Flex flexDir="column" alignItems="center">
              <CircularProgress
                value={UserStore.energy}
                color="green.400"
                size="300"
                ml="12px"
                mt="6px"
                thickness={8}
                capIsRound
              >
                <CircularProgressLabel>
                  <Text fontSize={36} color="black" fontWeight="medium">
                    Energy
                  </Text>
                  <Text fontSize={36} color="black" fontWeight="medium">
                    {UserStore.energy}/100
                  </Text>
                </CircularProgressLabel>
              </CircularProgress>
              <Text fontSize={24} color="black">
                Next refill:{' '}
                {`${date.getDate()}/${date.getMonth()}/${date.getFullYear()} 23:59:59`}
              </Text>
            </Flex>
            <Flex height="400px" mx={8}>
              <Divider orientation="vertical" size="12" />
            </Flex>
            <Flex flexDir="column" gridGap={2}>
              <Text fontSize={36} color="black" fontWeight="medium">
                Wallet Status
              </Text>
              <Flex flexDir="row" alignItems="center" mt={4}>
                <Text ml={8} fontSize={24} color="black">
                  DCC: {dccToken.toFixed(2)}
                </Text>
                <Button
                  ml={4}
                  colorScheme="teal"
                  size="md"
                  onClick={() =>
                    withdraw(
                      process.env.REACT_APP_DCC_ADDRESS as string,
                      Math.floor(UserStore.dcc),
                    )
                  }
                >
                  Withdraw
                </Button>
              </Flex>
              <Flex flexDir="row" alignItems="center" mt={4}>
                <Input
                  ml={8}
                  placeholder="Amount of DCC to deposit"
                  size="lg"
                  value={dccDepositAmount}
                  onChange={(e) => setDccDepositAmount(e.target.value)}
                />
                <Button
                  ml={4}
                  bgColor="blue.400"
                  size="md"
                  onClick={() =>
                    deposit(
                      process.env.REACT_APP_DCC_ADDRESS as string,
                      dccDepositAmount,
                    )
                  }
                >
                  Deposit
                </Button>
              </Flex>
              <Flex flexDir="row" alignItems="center" mt={4}>
                <Text ml={8} fontSize={24} color="black">
                  FSH: {fshToken.toFixed(2)}
                </Text>
                <Button
                  ml={4}
                  colorScheme="teal"
                  size="md"
                  onClick={() =>
                    withdraw(
                      process.env.REACT_APP_FSH_ADDRESS as string,
                      Math.floor(UserStore.fsh),
                    )
                  }
                >
                  Withdraw
                </Button>
              </Flex>
              <Flex flexDir="row" alignItems="center" mt={4}>
                <Text ml={8} fontSize={24} color="black">
                  Pending Reward: {pendingReward.toFixed(2)}
                </Text>
                <Button
                  ml={4}
                  colorScheme="teal"
                  size="md"
                  onClick={() =>
                    claimReward(process.env.REACT_APP_FSH_ADDRESS as string)
                  }
                >
                  Claim
                </Button>
              </Flex>
              <Flex flexDir="row" alignItems="center" mt={4}>
                <Input
                  ml={8}
                  placeholder="Amount of FSH to deposit"
                  size="lg"
                  value={fshDepositAmount}
                  onChange={(e) => setFshDepositAmount(e.target.value)}
                />
                <Button
                  ml={4}
                  bgColor="blue.400"
                  size="md"
                  onClick={() =>
                    deposit(
                      process.env.REACT_APP_FSH_ADDRESS as string,
                      fshDepositAmount,
                    )
                  }
                >
                  Deposit
                </Button>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default TokenDialog;
