/* eslint-disable */
import Web3 from "web3";
import web3 from "./web3";
import TokenManagerABI from "./TokenManager.json";
import BigNumber from "bignumber.js";

export let TOKEN_DEPOSITER_ADDRESS = process.env.REACT_APP_TOKEN_DEPOSITER_CONTRACT;

export default class TokenDepositer {
  contract: any
  walletAddressPlayer: any
  walletAddress: any
  isLeader: boolean
  
  constructor(walletAddressPlayer = "", walletAddress = "") {
    this.contract = new web3.eth.Contract(TokenManagerABI as any, TOKEN_DEPOSITER_ADDRESS)
    this.walletAddressPlayer = walletAddressPlayer;
    this.walletAddress = walletAddress;
    this.isLeader = walletAddressPlayer != walletAddress;
  }

  async depositERC20(token: string, amount: string | number) {
    if (!this.isLeader) {
      return await this.contract.methods.depositERC20(token, amount).send({from: this.walletAddressPlayer});
    } else {

    }
  }

  async depositERC721(token: string, tokenId: string | number, data: string) {
    if (!this.isLeader) {
      return await this.contract.methods.depositERC721(token, tokenId, data).send({from: this.walletAddressPlayer});
    } else {

    }
  }

  async depositERC1155(token: string, tokenId: string | number, amount: string | number, data: string) {
    if (!this.isLeader) {
      return await this.contract.methods.depositERC1155(token, tokenId, amount, data).send({from: this.walletAddressPlayer});
    } else {

    }
  }

  async executeMetaTransaction(functionSignature: string, r: string, s: string, v: number) {
    console.log(process.env.REACT_APP_TOKEN_MANAGER_OPERATOR);
    return await this.contract.methods.executeMetaTransaction(
      process.env.REACT_APP_TOKEN_MANAGER_OPERATOR,
      functionSignature,
      r,
      s,
      v,
    ).send({from: this.walletAddressPlayer});
  }
}
