import React from 'react';
import { Box, Button, useTab } from '@chakra-ui/react';

const MarketplaceTab = React.forwardRef((props: any, ref): JSX.Element => {
  const tref = ref || undefined;
  const tabProps = useTab({ ...props, tref });
  const isSelected = !!tabProps['aria-selected'];

  const styles = isSelected
    ? {
        variant: 'solidwhite',
        fontSize: '48px',
        px: '34px',
        h: '84px',
      }
    : {
        variant: 'linkHeader',
        fontSize: '48px',
        px: '34px',
        h: '84px',
        color: 'white',
        rounded: 'xl',
      };

  return (
    <Box {...props}>
      <Button {...styles} {...tabProps}>
        {tabProps.children}
      </Button>
    </Box>
  );
});

export default MarketplaceTab;
