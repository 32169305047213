import React, { useEffect } from 'react';
import './style.css';
import { Box, Flex, Grid, GridItem, Image } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';

import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import Chess from '../Chess';
import {
  activeUnitAttackAndSubmit,
  GameState,
  getPositionCode,
  ITileData,
  moveActiveUnitAndSubmit,
  moveUnitAndSubmit,
  placeNewUnit,
} from '../../../stores/Game';

import { useStores } from '../../../stores';

type BoardProps = {
  boardData: Array<Array<ITileData>>;
};

const Board: React.FC<BoardProps> = observer(
  (props: BoardProps): JSX.Element => {
    const { GameStore, UserStore } = useStores();

    console.log(toJS(props.boardData));
    console.log(
      'wh',
      GameStore.getWidth(),
      GameStore.height,
      GameStore.getBoardData(),
    );

    const renderBoard = (): Array<JSX.Element> => {
      const tiles: Array<JSX.Element> = [];
      props.boardData.forEach((rows: Array<ITileData>, row: number) => {
        rows.forEach((tileData: ITileData, col: number) => {
          let tileBackgroundColor = '';
          let tileImagePath = '';
          if (tileData.tileProperty === 'grass') {
            tileBackgroundColor =
              col % 2 === row % 2 ? 'green.500' : 'green.300';
            tileImagePath =
              col % 2 === row % 2
                ? '/images/grass_dark.png'
                : '/images/grass_light.png';
          } else if (tileData.tileProperty === 'water') {
            tileBackgroundColor = col % 2 === row % 2 ? 'blue.400' : 'blue.300';
            tileImagePath = '/images/water.png';
          } else {
            tileBackgroundColor =
              col % 2 === row % 2 ? 'green.500' : 'green.300';
            tileImagePath =
              col % 2 === row % 2
                ? '/images/forest_w_bg_1.png'
                : '/images/forest_w_bg_2.png';
          }
          tiles.push(
            <GridItem
              height="100%"
              width="100%"
              rowSpan={1}
              colSpan={1}
              background={tileBackgroundColor}
              position="relative"
              key={`${row + 1},${col + 1}`}
            >
              <Image
                src={tileImagePath}
                width={`${600 / GameStore.getWidth()}px`}
                height={`${720 / GameStore.getHeight()}px`}
                position="absolute"
                key="grid_image"
              />

              {tileData.isHighlighted && !tileData.unit ? (
                <Flex
                  height="100%"
                  width="100%"
                  align="center"
                  justifyContent="center"
                  position="absolute"
                  key={`${row + 1},${col + 1}`}
                >
                  <Image
                    src="/images/grass_active.png"
                    width={`${600 / GameStore.getWidth()}px`}
                    height={`${720 / GameStore.getHeight()}px`}
                    position="absolute"
                    key={`${row + 1},${col + 1}`}
                  />
                </Flex>
              ) : (
                <></>
              )}

              <Flex
                width="100%"
                height="100%"
                position="absolute"
                background={
                  GameStore.highlightSrc === getPositionCode(row, col) ||
                  GameStore.highlightDest === getPositionCode(row, col)
                    ? '#751fff30'
                    : GameStore.highlightHistSrc ===
                        getPositionCode(row, col) ||
                      GameStore.highlightHistDest === getPositionCode(row, col)
                    ? '#f81fff2f'
                    : 'none'
                }
              />
              <Flex
                height="100%"
                width="100%"
                align="center"
                justifyContent="center"
                position="absolute"
                key="chess_container"
                onClick={() => {
                  // Placing
                  if (
                    (GameStore.gameState === GameState.PLACING ||
                      GameStore.gameState ===
                        GameState.ENEMY_FINISHED_PLACING) &&
                    !tileData.unit &&
                    tileData.tileProperty === 'grass'
                  ) {
                    console.log('glass', row, col, GameStore.myChesses?.units);
                    placeNewUnit(row, col);
                    return;
                  }

                  // Playing
                  if (tileData.unit && !tileData.isHighlighted) {
                    GameStore.setShowingUnit({
                      unit: { ...tileData.unit },
                    });
                  }
                  if (
                    tileData.unit &&
                    !tileData.isEnemy &&
                    (GameStore.gameState === GameState.MOVE_OR_ATTACK ||
                      GameStore.gameState === GameState.MOVE_ONLY)
                  ) {
                    GameStore.setActiveUnit({
                      unit: tileData.unit,
                      row,
                      col,
                    });
                  } else if (tileData.isHighlighted) {
                    if (tileData.unit && tileData.isEnemy) {
                      activeUnitAttackAndSubmit(row, col);
                      GameStore.setActiveUnit(undefined);
                      if (tileData.unit) {
                        GameStore.setShowingUnit({
                          unit: { ...tileData.unit },
                        });
                      }
                    } else {
                      moveActiveUnitAndSubmit(row, col);
                    }
                    GameStore.setGameState(GameState.ENEMY_TURN);
                    GameStore.setConsequenceSkip(0);

                    const { currentTurn } = GameStore;
                    GameStore.setCurrentTurn(currentTurn + 1);
                  } else {
                    GameStore.setActiveUnit(undefined);
                  }
                }}
              >
                <Flex
                  width="100%"
                  height="100%"
                  position="absolute"
                  _hover={{
                    background: `${
                      (GameStore.gameState === GameState.PLACING &&
                        tileData.tileProperty === 'grass') ||
                      GameStore.gameState ===
                        GameState.ENEMY_FINISHED_PLACING ||
                      (tileData.unit && !tileData.isEnemy)
                        ? 'whiteAlpha.500'
                        : 'none'
                    }`,
                  }}
                  _active={{
                    background: `${
                      tileData.unit && !tileData.isEnemy
                        ? 'whiteAlpha.600'
                        : 'none'
                    }`,
                  }}
                />
                <AnimatePresence
                  initial={false}
                  exitBeforeEnter
                  onExitComplete={() => null}
                >
                  {tileData.unit ? (
                    <Chess
                      chess={tileData.unit}
                      key="chess"
                      isEnemy={tileData.isEnemy}
                    />
                  ) : tileData.isItem ? (
                    <Image src="/images/marketplace_blue_chess.png" />
                  ) : (
                    <></>
                  )}
                </AnimatePresence>
              </Flex>

              {tileData.isHighlighted && tileData.unit ? (
                <Flex
                  height="100%"
                  width="100%"
                  align="center"
                  justifyContent="center"
                  position="absolute"
                  style={{
                    pointerEvents: 'none',
                  }}
                >
                  <Image src="/images/grass_fight.png" position="absolute" />
                </Flex>
              ) : (
                <></>
              )}
            </GridItem>,
          );
        });
      });
      return tiles;
    };

    return (
      <Grid
        maxHeight="720px"
        maxWidth="600px"
        minHeight="720px"
        minWidth="600px"
        templateRows={`repeat(${GameStore.getHeight()}, 1fr)`}
        templateColumns={`repeat(${GameStore.getWidth()}, 1fr)`}
        boxShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
      >
        {(GameStore.gameState === GameState.PLACING ||
          GameStore.gameState === GameState.ENEMY_FINISHED_PLACING ||
          GameStore.gameState === GameState.ENEMY_PLACING) &&
          !GameStore.isPlayer2 && (
            <Box
              position="absolute"
              width="600px"
              height="360px"
              textAlign="center"
              color="white"
              fontSize="32px"
              fontWeight="bold"
              p="72px"
              px="140px"
              zIndex="1"
              background="linear-gradient(180deg, #375081 28.7%, rgba(44, 67, 113, 0) 109.95%, rgba(32, 54, 97, 0) 124.54%) "
            >
              Enemy is also placing their chesses
            </Box>
          )}
        {(GameStore.gameState === GameState.PLACING ||
          GameStore.gameState === GameState.ENEMY_FINISHED_PLACING ||
          GameStore.gameState === GameState.ENEMY_PLACING) &&
          GameStore.isPlayer2 && (
            <Box
              position="absolute"
              top="50%"
              width="600px"
              height="360px"
              textAlign="center"
              color="white"
              fontSize="32px"
              fontWeight="bold"
              p="72px"
              px="140px"
              zIndex="1"
              background="linear-gradient(360deg, #375081 28.7%, rgba(44, 67, 113, 0) 109.95%, rgba(32, 54, 97, 0) 124.54%) "
            >
              Enemy is also placing their chesses
            </Box>
          )}
        {renderBoard()}
      </Grid>
    );
  },
);

export default Board;
