import { ChessClass } from 'modules/types/Chess';

export const parseChess = (chess: any) => {
  return {
    name: chess.name,
    id: chess.id,
    img: `/images/${chess.chessClass.toLowerCase()}.png`,
    chessType: chess.chessClass,
    maxHp: chess.hp,
    curHp: chess.hp,
    atk: chess.atk,
    def: chess.def,
    spd: chess.spd,
    skill: 'skill',
    slot: chess.slot,
    price: chess.price || 0,
    saleListed: chess.saleListed,
    nftId: chess.nftId || '',
    playerId: chess.playerId || 0,
  };
};

export const parseChessResponse = (chess: any) => {
  return {
    name: chess.name,
    chess_id: chess.id.toString(),
    img: `/images/${chess.chessClass.toLowerCase()}.png`,
    type: chess.chessClass,
    max_hp: chess.hp,
    hp: chess.hp,
    atk: chess.atk,
    def: chess.def,
    spd: chess.spd,
    position: chess.position,
    timestamp: chess.timestamp || '',
    skill: 'skill',
    slot: chess.slot,
    price: chess.price || 0,
    saleListed: chess.saleListed,
    nftId: chess.nftId || '',
    playerId: chess.playerId || 0,
  };
};

export const parseChesses = (chesses: any) => {
  return chesses.map((chess: any) => parseChess(chess));
};

export const parseChessesResponse = (chesses: any) => {
  return chesses.map((chess: any) => parseChessResponse(chess));
};

export const ChessAction: any = {
  K: ChessClass.King,
  N: ChessClass.Knight,
  R: ChessClass.Rook,
  B: ChessClass.Bishop,
};
