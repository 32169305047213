import axios from 'axios';
import { getJWTToken } from 'common/utils/token';
import { ChessClass, ChessSlot } from 'modules/types/Chess';

export const backend = (path: string) => {
  return `${process.env.REACT_APP_API}${path}`;
};

export const getBearerAuth = () => {
  return { headers: { Authorization: `Bearer ${getJWTToken()}` } };
};

export const getChessPieces = async (clientId: string) => {
  const { data } = await axios.get(
    backend(`/items/get-user-items?public_address=${clientId}`),
  );
  return data;
};

export const getAllChessPieces = async (clientId: string) => {
  const { data } = await axios.get(
    backend(`/items/get-all-user-items?public_address=${clientId}`),
  );
  return data;
};

export const postPlaceChessPieces = async (
  room_id: string,
  client_id: string,
  position: { [key: string]: string }[],
) => {
  const { data } = await axios.post(
    backend('/users/places-chess-pieces'),
    { room_id, client_id, position },
    getBearerAuth(),
  );
  return data;
};

export const patchOnUsedItem = async (chessId: string, slot: ChessSlot) => {
  const { data } = await axios.patch(
    backend(`/items/set-on-use/${chessId}/${slot}`),
  );
  return data;
};

export const postTurnHandler = async (
  room_id: string,
  client_id: string,
  chess_id: string,
  move: string,
  state: 'MOVE' | 'ATTACK',
  canAttack = false,
) => {
  console.log('client id', client_id);
  const { data } = await axios.post(
    backend('/users/turn-handler'),
    { room_id, client_id, chess_id, move, state, canAttack },
    getBearerAuth(),
  );
  return data;
};

export const postTurnHandlerDoNothing = async (
  room_id: string,
  client_id: string,
) => {
  const { data } = await axios.post(backend('/users/turn-handler'), {
    room_id,
    client_id,
    state: 'NOTHING',
  });
  return data;
};

export const signUp = async (public_address: string) => {
  const { data } = await axios.post(backend('/users/sign-up'), {
    public_address,
  });
  return data;
};

export const buyNFT = async (chessClass: ChessClass, name?: string) => {
  const accessToken = `Bearer ${getJWTToken()}`;
  const _chessClass = chessClass.toUpperCase();
  console.log('access token:', accessToken);
  const data = await axios.post(
    backend('/items/create'),
    {
      chessClass: _chessClass,
      name,
    },
    {
      headers: {
        Authorization: accessToken,
      },
    },
  );
  console.log('data (buy NFT):', data);

  return data;
};

export const logIn = async (public_address: string, signature: string) => {
  const { data } = await axios.post(backend('/users/login'), {
    public_address,
    signature,
  });
  return data;
};

export const getUserInfo = async (client_id: string) => {
  const { data } = await axios.get(
    backend(`/users/get-user-info?client_id=${client_id}`),
    getBearerAuth(),
  );
  return data;
};

export const getListedChesses = async (
  offset: number,
  limit: number,
  sortMode: number,
  chessClass?: ChessClass,
) => {
  let url = `/items/get-sale-listed?offset=${offset}&limit=${limit}`;
  if (chessClass) url += `&chessClass=${chessClass}`;
  switch (sortMode) {
    case 0:
      url += `&id=asc`;
      break;
    case 1:
      url += `&id=desc`;
      break;
    case 2:
      url += `&price=asc`;
      break;
    case 3:
      url += `&price=desc`;
      break;
    default:
      break;
  }
  const data = await axios.get(backend(url), getBearerAuth());
  return data;
};

export const patchListedChess = async (nftId: string, price: number) => {
  const { data } = await axios.patch(
    backend('/items/list-on-sale'),
    { nftId, price },
    getBearerAuth(),
  );
  console.log(data);
};

export const patchUnlistedChess = async (nftId: string) => {
  const { data } = await axios.patch(
    backend('/items/unlist-on-sale'),
    { nftId },
    getBearerAuth(),
  );
  console.log(data);
};

export const postBuyP2P = async (buyer: string, nftId: string) => {
  const { data } = await axios.post(
    backend(`/items/buy-p2p?buyer=${buyer}&nftId=${nftId}`),
    {},
    getBearerAuth(),
  );
  console.log(data);
};

export const getUserRanking = async () => {
  const { data } = (await axios.get(backend(`/users/get-ranking`))) as any;
  return data.message;
};

export const postSurrender = async (roomid: string) => {
  const { data } = await axios.post(
    backend(`/users/surrender?roomid=${roomid}`),
    {},
    getBearerAuth(),
  );
  console.log(data);
};

export const patchUpdateName = async (nftId: string, name: string) => {
  const { data } = await axios.patch(
    backend(`/items/update-name`),
    { nftId, name },
    getBearerAuth(),
  );
  console.log(data);
};

export const getMap = async (roomid: string, width: number, height: number) => {
  const { data }: { data: any } = await axios.get(
    backend(`/users/get-map?roomId=${roomid}&width=${width}&height=${height}`),
    getBearerAuth(),
  );
  return data.message;
};
