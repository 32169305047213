import { ethers } from 'ethers';
import web3 from 'web3';

import { logIn, signUp } from 'client';
import { exchangeTokenApiToken } from 'common/utils/tokenApi/api';

declare let window: any;

export const provider =
  window.ethereum && new ethers.providers.Web3Provider(window.ethereum);

export const signer = provider && provider.getSigner();

export const isMetaMaskInstalled = () => {
  return Boolean(window.ethereum && window.ethereum.isMetaMask);
};

export const isLoggedIn = async () => {
  const accounts =
    window.ethereum &&
    (await window.ethereum.request({ method: 'eth_accounts' }));
  return !!accounts;
};

export const getAccount = async () => {
  const accounts =
    window.ethereum &&
    (await window.ethereum.request({ method: 'eth_accounts' }));
  return accounts && accounts[0];
};

export const getChainId = async () => {
  const chainId = await window.ethereum.request({ method: 'eth_chainId'});
  return chainId
}

export const connectWallet = async (onError?: Function, onFinal?: Function) => {
  try {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
    const chainId = await getChainId()
    console.log(chainId)
    if (chainId !== process.env.REACT_APP_CHAIN_ID) {
      throw new Error(`Please switch the network to ${process.env.REACT_APP_CHAIN_NAME}`)
    }

    const publicAddress = await getAccount();
    const userData = (await signUp(publicAddress)) as any;

    console.log(userData.message.nonce);

    const signature = await signer.signMessage(
      userData.message.nonce.toString(),
    );
    console.log('signature', signature);

    const loggedInData = (await logIn(publicAddress, signature)) as any;
    console.log(loggedInData);
    localStorage.setItem('jwt', loggedInData.message);
    await exchangeTokenApiToken(loggedInData.message);
  } catch (error: any) {
    onError?.(error.message);
  } finally {
    onFinal?.();
  }
};
