import { extendTheme } from '@chakra-ui/react';
import globalStyles from './globalStyle';
import Button from './components/button';
import Input from './components/input';

const overrides = {
  styles: {
    globalStyles,
  },
  // borders,
  // Other foundational style overrides go here
  components: {
    Button,
    Input,
    // Other components go here
  },
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme(overrides);

export default theme;
