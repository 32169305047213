import React, { useEffect } from 'react';
import './style.css';
import { Button, Flex, Image, Spacer, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import ConnectWalletButton from 'modules/components/ConnectWalletButton';

const FirstVisit: React.FC<any> = observer((): JSX.Element => {
  return (
    <Flex
      backgroundColor="#777DED"
      minHeight="100%"
      minWidth="100%"
      align="center"
      alignItems="center"
      justify="center"
      justifyContent="center"
      flexDirection="column"
      overflow="hidden"
    >
      <div className="avatar">
        <Image src="/images/home_chess_logo.png" />
        <Text
          color="#203661"
          fontWeight="semibold"
          fontSize="24px"
          marginTop="12px"
        >
          Please connect to your
        </Text>
        <Text color="#203661" fontWeight="semibold" fontSize="24px">
          wallet to continue.
        </Text>
        <ConnectWalletButton
          variant="roundsolid"
          size="solidxl"
          marginTop="12px"
        />
      </div>
      <Spacer />
      <Image width="100%" src="/images/static_tree_background.png" />
    </Flex>
  );
});

export default FirstVisit;
