import React, { useEffect, useState } from 'react';
import './App.css';
import { ChakraProvider, Box, Flex, useDisclosure } from '@chakra-ui/react';
import { useStores } from 'stores';
import { useLocation } from 'react-router';
import { getAccount } from 'common/blockchain';
import { getJWTToken } from 'common/utils/token';
import WebHeader from './modules/components/WebHeader';
import Theme from './modules/themes';
import Router from './Router';

declare global {
  interface Window {
    ethereum: any;
  }
}

const App: React.FC<any> = (): JSX.Element => {
  const { GameStore, UserStore } = useStores();
  const pathName = useLocation();
  const webHeaderHeight = 150;

  // Path that you don't want to render navbar must be included here in this list
  const pathsNotToRenderNavBar = ['/game'];

  const logout = () => {
    localStorage.clear();
    UserStore.setClientId('');
    UserStore.setJWT('');
  };

  useEffect(() => {
    GameStore.afterCreate();
    const initAccount = async () => {
      const account = await getAccount();
      if (account) UserStore.setClientId(account);
      const jwt = getJWTToken();
      if (jwt) UserStore.setJWT(jwt);
    };
    initAccount();

    if (window.ethereum) {
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
        logout();
      });
      window.ethereum.on('accountsChanged', () => {
        window.location.reload();
        logout();
      });
    }
  }, []);

  return (
    <ChakraProvider theme={Theme}>
      {!pathsNotToRenderNavBar.some((path) =>
        pathName.pathname.includes(path),
      ) ? (
        // Sending a function to this child component so that we can make a change on our static webheader size that's determine in here
        // Not the best way to do it but it works for me
        <WebHeader />
      ) : (
        <></>
      )}
      <Box
        width="100%"
        position="relative"
        zIndex="1"
        id="scroller"
        height={
          !pathsNotToRenderNavBar.some((path) =>
            pathName.pathname.includes(path),
          )
            ? // Sending a function to this child component so that we can make a change on our static webheader size that's determine in here
              // Not the best way to do it but it works for me
              `calc(100vh - ${webHeaderHeight}px)`
            : '100vh'
        }
      >
        <Router />
      </Box>
    </ChakraProvider>
  );
};

export default App;
