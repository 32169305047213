import { ethers } from 'ethers';
import DechessStakingABI from './DechessStaking.json';

export class DechessStaking {
  contract: ethers.Contract;

  constructor(address: string) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(address, DechessStakingABI, signer);

    this.contract = contract;
  }

  async rewardPerBlock() {
    return this.contract.rewardPerBlock();
  }

  async globalPoolLimit() {
    return this.contract.globalPoolLimit();
  }

  async totalStaked() {
    return this.contract.totalStaked();
  }

  async userStaked(address: string) {
    return (await this.contract.userInfo(address)).amount;
  }

  async pendingReward(address: string) {
    return this.contract.pendingReward(address);
  }

  async deposit(amount: any) {
    return this.contract.deposit(amount);
  }

  async withdraw(amount: any) {
    return this.contract.withdraw(amount);
  }

  async harvest() {
    return this.deposit(0);
  }
}
