import RequireAuth from 'modules/components/RequireAuth';
import Ranking from 'modules/pages/Ranking';
import Staking from 'modules/pages/Staking';
import React from 'react';
/*
import logo from './logo.svg';
*/
import { Route, Switch } from 'react-router-dom';
import FirstVisit from './modules/pages/FirstVisit';
import Game from './modules/pages/Game';
import Home from './modules/pages/Home';
import Manage from './modules/pages/Manage';
import Marketplace from './modules/pages/Marketplace';

/*
const Home = loadable(() => import('./scenes/Home'));
const Login = loadable(() => import('./scenes/Login'));
const Signup = loadable(() => import('./scenes/Signup'));
const Playground = loadable(() => import('./Playground'));
*/

const Router: React.FC<any> = (): JSX.Element => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        component={() => (
          <RequireAuth requireNoAuth redirectPath="/home">
            <FirstVisit />
          </RequireAuth>
        )}
      />
      <Route
        path="/game"
        component={() => (
          <RequireAuth>
            <Game />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path="/home"
        component={() => (
          <RequireAuth>
            <Home />
          </RequireAuth>
        )}
      />
      <Route
        exact
        path="/manage"
        component={() => (
          <RequireAuth>
            <Manage />
          </RequireAuth>
        )}
      />
      <Route
        path="/staking"
        component={() => (
          <RequireAuth>
            <Staking />
          </RequireAuth>
        )}
      />
      <Route exact path="/marketplace" component={() => <Marketplace />} />
      <Route exact path="/ranking" component={() => <Ranking />} />
    </Switch>
  );
};

export default Router;
