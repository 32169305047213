import * as React from 'react';
import {
  Box,
  Button,
  Collapse,
  Flex,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  PopoverHeader,
  PopoverCloseButton,
  Spacer,
  Text,
  useColorModeValue as mode,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import UserIcon from 'common/icons/UserIcon';
import { Link, useLocation } from 'react-router-dom';
import { useWindowSize } from '../../../common/utils';
import ConnectWalletButton from '../ConnectWalletButton';
import StatusBox from '../StatusBox';

const ghostHeaderStyle = {
  variant: 'linkHeader',
  size: 'solidxl',
  color: 'white',
};

const highlightedHeaderStyle = {
  variant: 'solidwhite',
  size: 'solidxl',
  zIndex: '11',
};

const HeaderMenuBoxStyle = {
  backgroundColor: '#7377F5',
  padding: '12px',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  width: '100%',
};

const WebHeader: React.FC<any> = observer((): JSX.Element => {
  const { UserStore } = useStores();
  const isAuth = !!UserStore.jwt && !!UserStore.clientId;
  const [width] = useWindowSize();

  const location = useLocation();
  const { pathname } = location;

  const logout = () => {
    localStorage.clear();
    UserStore.setClientId('');
    UserStore.setJWT('');
  };

  const renderCloud = (): Array<JSX.Element> => {
    const cloudList: Array<JSX.Element> = [];
    const numberOfCloud = width / 416;
    for (let i = 0; i <= numberOfCloud; i++) {
      cloudList.push(
        <Image
          src="/images/header_cloud.png"
          width="416px"
          height="150px"
          position="absolute"
          marginLeft={416 * i}
          zIndex="10"
        />,
      );
    }
    return cloudList;
  };

  const { isOpen, onToggle } = useDisclosure();

  const minimumWidthToRenderMenu = isAuth ? 1360 : 1210;

  return (
    <Flex
      backgroundColor={isOpen ? '#7377F5' : '#777DED'}
      flexDirection="column"
    >
      <Flex
        top="0"
        width="100%"
        minWidth="100%"
        maxWidth="100%"
        height="150px"
        position="sticky"
        alignItems="center"
        zIndex="2"
        overflowX="hidden"
      >
        {width <= minimumWidthToRenderMenu ? (
          <>
            <Button
              onClick={() => {
                onToggle();
              }}
            >
              <Image
                src="/images/menu_icon.png"
                height="50px"
                marginLeft="40px"
                marginBottom="10px"
                zIndex="11"
              />
            </Button>
          </>
        ) : (
          <>
            <Image
              src="/images/logo.png"
              height="90px"
              paddingLeft="40px"
              marginBottom="50px"
              zIndex="11"
            />
            <Box marginBottom="18px" marginLeft="60px" zIndex="11">
              <Link to="/home">
                <Button
                  {...(pathname === '/home'
                    ? highlightedHeaderStyle
                    : ghostHeaderStyle)}
                >
                  Home
                </Button>
              </Link>
              <Link to="/marketplace">
                <Button
                  {...(pathname === '/marketplace'
                    ? highlightedHeaderStyle
                    : ghostHeaderStyle)}
                  marginLeft="20px"
                >
                  Marketplace
                </Button>
              </Link>
              <Link to="/staking">
                <Button
                  {...(pathname === '/staking'
                    ? highlightedHeaderStyle
                    : ghostHeaderStyle)}
                  marginLeft="12px"
                >
                  Staking
                </Button>
              </Link>
              <Link to="/ranking">
                <Button
                  {...(pathname === '/ranking'
                    ? highlightedHeaderStyle
                    : ghostHeaderStyle)}
                  marginLeft="12px"
                >
                  Ranking
                </Button>
              </Link>
              <a
                href="https://dechess.gitbook.io/dechess/M601Xndq2FINymoRwHww/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button {...ghostHeaderStyle} marginLeft="12px">
                  Docs <ExternalLinkIcon ml="5px" />
                </Button>
              </a>
            </Box>
          </>
        )}
        <Spacer />
        {isAuth ? (
          <Box
            zIndex="20"
            pr="50px"
            display="flex"
            flexDir="row"
            alignItems="center"
            gridGap="50px"
          >
            <StatusBox />
            <Popover placement="left-end">
              <PopoverTrigger>
                <IconButton
                  height="71px"
                  variant="unstyled"
                  aria-label="User"
                  fontSize="20px"
                  icon={<UserIcon size="71" />}
                  isRound
                />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverHeader fontWeight="semibold">
                  {UserStore.clientId}
                </PopoverHeader>
                <PopoverArrow />
                <PopoverBody>
                  <Button onClick={logout}>Log out</Button>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        ) : (
          <ConnectWalletButton
            marginBottom="18px"
            marginRight="50px"
            zIndex="11"
          />
        )}
        {renderCloud()}
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <VStack backgroundColor="#8387F6" spacing="2px" paddingBottom="2px">
          <Flex {...HeaderMenuBoxStyle}>
            <Link to="/home">
              <Button {...ghostHeaderStyle} padding="0px">
                Home
              </Button>
            </Link>
          </Flex>
          <Flex {...HeaderMenuBoxStyle}>
            <Link to="/marketplace">
              <Button {...ghostHeaderStyle} marginLeft="20px">
                Marketplace
              </Button>
            </Link>
          </Flex>
          <Flex {...HeaderMenuBoxStyle}>
            <Link to="/staking">
              <Button {...ghostHeaderStyle} marginLeft="12px">
                Staking
              </Button>
            </Link>
          </Flex>
          <Flex {...HeaderMenuBoxStyle}>
            <Link to="/ranking">
              <Button {...ghostHeaderStyle} marginLeft="12px">
                Ranking
              </Button>
            </Link>
          </Flex>
          <Flex {...HeaderMenuBoxStyle}>
            <a
              href="https://dechess.gitbook.io/dechess/M601Xndq2FINymoRwHww/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button {...ghostHeaderStyle} marginLeft="12px">
                Docs <ExternalLinkIcon />
              </Button>
            </a>
          </Flex>
        </VStack>
      </Collapse>
    </Flex>
  );
});

export default WebHeader;
