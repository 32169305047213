import React, { useEffect, useState } from 'react';
import { Flex, Image, Spacer, Text, Tooltip } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import RankingTable from 'modules/components/RankingTable';
import { getUserRanking } from 'client';
import { QuestionIcon, QuestionOutlineIcon } from '@chakra-ui/icons';

const Ranking: React.FC<any> = observer((): JSX.Element => {
  return (
    <Flex
      backgroundColor="#777DED"
      minHeight="100%"
      minWidth="100%"
      align="center"
      alignItems="center"
      justify="center"
      justifyContent="center"
      flexDirection="column"
      overflow="hidden"
      paddingTop="50px"
    >
      <Text
        color="white"
        fontWeight="bold"
        fontSize="48px"
        textShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
        mb="30px"
      >
        Ranking
        <Tooltip
          label="The first x places in each season will receive award as DCC."
          placement="bottom-start"
        >
          <QuestionIcon
            ml="10px"
            fontSize="24px"
            textShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
          />
        </Tooltip>
      </Text>
      <RankingTable />
      <Spacer />
      <Image width="100%" src="/images/static_tree_background.png" />
    </Flex>
  );
});

export default Ranking;
