import React, { useEffect, useState } from 'react';
import { Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { getUserRanking } from 'client';
import { useStores } from 'stores';

const RankingTable: React.FC<any> = observer((): JSX.Element => {
  const { UserStore } = useStores();
  const [userRanking, setUserRanking] =
    useState<{ elo: number; public_address: string }[]>();
  useEffect(() => {
    getUserRanking().then((result) => {
      setUserRanking(result as any);
    });
  }, []);
  return (
    <Flex width="1200px" borderRadius="20px" overflow="hidden">
      <Table variant="simple" bgColor="white" size="lg">
        <Thead>
          <Tr>
            <Th>Ranking</Th>
            <Th>Address</Th>
            <Th>ELO</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userRanking &&
            userRanking.map((rank, idx) => {
              return (
                <Tr
                  {...(rank.public_address === UserStore.clientId
                    ? { bgColor: 'purple.200' }
                    : {})}
                >
                  <Td>
                    {idx + 1}
                    {idx === 0 && ' 🥇'}
                    {idx === 1 && ' 🥈'}
                    {idx === 2 && ' 🥉'}
                  </Td>
                  <Td>{rank.public_address}</Td>
                  <Td>{rank.elo || 0}</Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </Flex>
  );
});

export default RankingTable;
