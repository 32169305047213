import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spacer,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Text,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { buyNFT, getListedChesses } from 'client';
import { useStores } from 'stores';
import { fetchTokens } from 'common/utils/tokenApi/fetchTokens';
import { parseChess, parseChesses } from 'common/utils/Chess';
import { ChessClass, ChessType } from 'modules/types/Chess';
import LoadingDialog from 'modules/components/loadingDialog';
import ChessInfoDialog from 'modules/components/ChessInfoDialog';
import ChessCard from 'modules/components/ChessCard';
import ConfirmDialog from 'modules/components/ConfirmDialog';
import web3 from 'common/utils/tokenApi/web3';
import MarketplaceTab from '../../components/MarketplaceTab';

const Marketplace: React.FC<any> = observer((): JSX.Element => {
  const { UserStore } = useStores();
  const [isLoading, setLoading] = useBoolean(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedChessClass, setSelectedChessClass] = useState<
    ChessClass | undefined
  >();
  const [newChess, setNewChess] = useState<ChessType>();
  const [openDialog, setOpenDialog] = useBoolean(false);
  const [listedChesses, setListedChesses] = useState<ChessType[]>([]);
  const toast = useToast();
  // Fetch the price and put it in this state if the price is not static at 200
  const [systemSalesPrices, setSystemSalesPrices] = useState({
    King: '200',
    Rook: '200',
    Knight: '200',
    Bishop: '200',
  });
  const [p2pSearchClass, setP2pSearchClass] = useState('All classes');
  const [queryChessClass, setQueryChessClass] = useState<
    ChessClass | undefined
  >();
  const [p2pSearchSort, setP2pSearchSort] = useState('Ascending ID');
  const [sortMode, setSortMode] = useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  useEffect(() => {
    getListedChesses(0, 20, sortMode, queryChessClass).then((res: any) => {
      setListedChesses(parseChesses(res.data.message.listedItems || []));
    });
  }, [queryChessClass, sortMode]);

  async function refreshData() {
    const tokens = await fetchTokens();
    // todo: set dcc and fsh tokens
    tokens?.forEach((token) => {
      console.log('token balance', token.balance);

      let tokenBalance = parseFloat(
        token.balance.slice(0, token.balance.length - 18),
      );
      tokenBalance = Math.floor(tokenBalance * 100) / 100 || 0;

      let tokenPendingReward = parseFloat(
        web3.utils.fromWei(token.pendingReward),
      );
      tokenPendingReward = Math.floor(tokenPendingReward * 100) / 100;

      if (token.symbol === 'DCC') {
        UserStore.setDcc(tokenBalance);
      } else if (token.symbol === 'FSH') {
        UserStore.setFsh(tokenBalance);
        UserStore.setPendingReward(tokenPendingReward);
      }
    });

    // return tokens;
  }

  const buyChessNFT = async (name: string) => {
    const chessClass = name as ChessClass;
    setLoading.on();
    try {
      const newMintedChessData = (await buyNFT(chessClass, 'Unnamed')) as any;
      const { item } = newMintedChessData.data;
      const newMintedChess = parseChess(item);
      setNewChess(newMintedChess);
      setOpenDialog.on();
      refreshData();
    } catch (e: any) {
      toast({
        title: `${e.message}`,
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading.off();
      setSelectedChessClass(undefined);
    }
  };

  const renderSystemSalesMenu = (): Array<JSX.Element> => {
    return Object.entries(systemSalesPrices).map((keyValuePrices) => {
      const name = keyValuePrices[0];
      const price = keyValuePrices[1];
      return (
        <Box
          backgroundColor="white"
          boxShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
          rounded="2xl"
          width="300px"
          paddingBottom="16px"
          marginTop="20px"
        >
          <Flex
            align="center"
            alignItems="center"
            flexDirection="column"
            margin="20px"
          >
            <Text fontWeight="bold" fontSize="40px" color="#203661">
              {name.charAt(0).toUpperCase() + name.slice(1)}
            </Text>
            <Image
              src={`/images/marketplace_${name.toLowerCase()}_chess.png`}
              maxHeight="160px"
              marginTop="20px"
            />
            <Button
              variant="roundsolid"
              size="lg"
              backgroundImage="linear-gradient(225deg, #24E795 0%, #42CAE8 99.99%, #67E9F1 100%);"
              marginTop="50px"
              onClick={() => {
                setSelectedChessClass(name as ChessClass);
              }}
            >
              Get 1 for {price} FSH
            </Button>
          </Flex>
        </Box>
      );
    });
  };

  const renderListedChesses = (): Array<JSX.Element> => {
    return listedChesses.map((chess) => {
      return <ChessCard chess={chess} slot={chess.slot} listMode />;
    });
  };

  const renderP2pMarketMenu = (): JSX.Element => {
    return (
      <Flex>
        <Spacer />
        <Menu>
          <MenuButton
            as={Button}
            variant="solidwhite"
            size="lg"
            rightIcon={
              <Image
                src="/images/marketplace_down_icon.png"
                height="7px"
                marginLeft="40px"
                marginTop="5px"
              />
            }
            width="200px"
            mr="10px"
          >
            <Flex>{p2pSearchSort}</Flex>
          </MenuButton>
          <MenuList rounded="xl" maxWidth="80px">
            <MenuItem
              minH="40px"
              onClick={() => {
                setP2pSearchSort('Ascending ID');
                setSortMode(0);
              }}
            >
              <Text
                fontSize="18px"
                fontWeight="semibold"
                color="#6C70E6"
                marginLeft="18px"
              >
                Ascending ID
              </Text>
            </MenuItem>
            <MenuItem
              minH="48px"
              onClick={() => {
                setP2pSearchSort('Descending ID');
                setSortMode(1);
              }}
            >
              <Text
                fontSize="18px"
                fontWeight="semibold"
                color="#6C70E6"
                marginLeft="18px"
              >
                Descending ID
              </Text>
            </MenuItem>
            <MenuItem
              minH="40px"
              onClick={() => {
                setP2pSearchSort('Ascending Price');
                setSortMode(2);
              }}
            >
              <Text
                fontSize="18px"
                fontWeight="semibold"
                color="#6C70E6"
                marginLeft="20.5px"
              >
                Ascending Price
              </Text>
            </MenuItem>
            <MenuItem
              minH="40px"
              onClick={() => {
                setP2pSearchSort('Descending Price');
                setSortMode(3);
              }}
            >
              <Text
                fontSize="18px"
                fontWeight="semibold"
                color="#6C70E6"
                marginLeft="20.5px"
              >
                Descending Price
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton
            as={Button}
            variant="solidwhite"
            size="lg"
            rightIcon={
              <Image
                src="/images/marketplace_down_icon.png"
                height="7px"
                marginLeft="40px"
                marginTop="5px"
              />
            }
            width="180px"
          >
            <Flex>{p2pSearchClass}</Flex>
          </MenuButton>
          <MenuList rounded="xl" maxWidth="80px">
            <MenuItem
              minH="40px"
              onClick={() => {
                setP2pSearchClass('All classes');
                setQueryChessClass(undefined);
              }}
            >
              <Text
                fontSize="18px"
                fontWeight="semibold"
                color="#6C70E6"
                marginLeft="50px"
              >
                All classes
              </Text>
            </MenuItem>
            <MenuItem
              minH="48px"
              onClick={() => {
                setP2pSearchClass('King');
                setQueryChessClass(ChessClass.King);
              }}
            >
              <Image
                src="/images/marketplace_king_chess.png"
                height="30px"
                marginLeft="2px"
              />
              <Text
                fontSize="18px"
                fontWeight="semibold"
                color="#6C70E6"
                marginLeft="18px"
              >
                King
              </Text>
            </MenuItem>
            <MenuItem
              minH="40px"
              onClick={() => {
                setP2pSearchClass('Rook');
                setQueryChessClass(ChessClass.Rook);
              }}
            >
              <Image
                src="/images/marketplace_rook_chess.png"
                height="30px"
                marginLeft="5px"
              />
              <Text
                fontSize="18px"
                fontWeight="semibold"
                color="#6C70E6"
                marginLeft="20.5px"
              >
                Rook
              </Text>
            </MenuItem>
            <MenuItem
              minH="40px"
              onClick={() => {
                setP2pSearchClass('Knight');
                setQueryChessClass(ChessClass.Knight);
              }}
            >
              <Image src="/images/marketplace_knight_chess.png" height="30px" />
              <Text
                fontSize="18px"
                fontWeight="semibold"
                color="#6C70E6"
                marginLeft="20.5px"
              >
                Knight
              </Text>
            </MenuItem>
            <MenuItem
              minH="40px"
              onClick={() => {
                setP2pSearchClass('Bishop');
                setQueryChessClass(ChessClass.Bishop);
              }}
            >
              <Image
                src="/images/marketplace_bishop_chess.png"
                height="30px"
                marginLeft="5px"
              />
              <Text
                fontSize="18px"
                fontWeight="semibold"
                color="#6C70E6"
                marginLeft="20.5px"
              >
                Bishop
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    );
  };

  return (
    <Flex
      backgroundColor="#777DED"
      minHeight="100%"
      minWidth="100%"
      align="center"
      alignItems="center"
      justify="center"
      justifyContent="center"
      flexDirection="column"
      overflow="hidden"
    >
      <ConfirmDialog
        open={selectedChessClass !== undefined}
        title="Confirmation"
        body={`Would you like to purchase a ${selectedChessClass} with 200 FSH?`}
        onYes={() => buyChessNFT(selectedChessClass as string)}
        onCancel={() => setSelectedChessClass(undefined)}
      />
      <LoadingDialog open={isLoading} />
      <ChessInfoDialog
        chess={newChess}
        onCloseDialog={() => {
          setOpenDialog.off();
        }}
        open={openDialog}
        isNew
      />
      <Box
        backgroundColor="#777DED"
        minH="100%"
        paddingY="12"
        paddingX={{ sm: '6', lg: '8' }}
      >
        <Box
          flexDirection="column"
          overflow="hidden"
          minW={{ sm: 'xl', md: '2xl', lg: '2xl', xl: '8xl' }}
          mx={{ sm: 'auto' }}
        >
          <Tabs
            variant="unstyled"
            marginTop="20px"
            index={tabIndex}
            onChange={handleTabsChange}
            isLazy
          >
            <TabList>
              <MarketplaceTab marginLeft="8px">
                <Image src="/images/marketplace_blue_chess.png" />
                <Text marginLeft="20px">System Sales</Text>
              </MarketplaceTab>
              <MarketplaceTab pl="6">
                <Text>P2P Market</Text>
              </MarketplaceTab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <SimpleGrid paddingTop="30px" minChildWidth="332px">
                  {renderSystemSalesMenu()}
                </SimpleGrid>
              </TabPanel>
              <TabPanel>
                {renderP2pMarketMenu()}
                <SimpleGrid
                  marginTop="40px"
                  spacing="20px"
                  minChildWidth="300px"
                >
                  {renderListedChesses()}
                </SimpleGrid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
      <Spacer />
      <Image width="100%" src="/images/static_tree_background.png" />
    </Flex>
  );
});

export default Marketplace;
