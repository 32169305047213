/* eslint-disable */
import axios from 'axios';
import jwt from 'jsonwebtoken';
import web3 from './web3';
import { requestSignature } from './signature';
import { pollingFn } from './polling';

const SIGNATURE_PREFIX = 'DCC';
const SIGNATURE_TAIL = '\n\nDechess';

let ALREADY_LOGIN_SIGNATURE = false;

let accessToken = window.localStorage.getItem('WALLET_ACCESS_TOKEN');
let api = axios.create({
  // baseURL: process.env.REACT_APP_API_HOST,
  headers: accessToken
    ? {
        Authorization: `Bearer ${accessToken}`,
      }
    : {},
});

function refreshApi() {
  accessToken = window.localStorage.getItem('WALLET_ACCESS_TOKEN');
  api = axios.create({
    // baseURL: process.env.REACT_APP_API_HOST,
    headers: accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {},
  });
}

let WALLET_ADDRESS = '';

export function buildApiWalletSignature(args: (string | number)[]): string {
  return `${SIGNATURE_PREFIX}:${args.join(':')}:${SIGNATURE_TAIL}`;
}

export async function loginToWallet(
  walletAddressPlayer: string,
  walletAddress: string,
) {
  const timestamp = Math.floor(Date.now() / 1000);
  const signature = await requestSignature(
    buildApiWalletSignature(['login', timestamp]),
  );

  const response: any = await callTokenApi({
    action: 'token',
    args: {
      walletAddressPlayer,
      walletAddress,
      signature,
      signatureTimestamp: timestamp,
    },
  });

  console.log(response);

  window.localStorage.setItem('WALLET_ACCESS_TOKEN', response.token);
  refreshApi();
}

export function getWalletAddress(web3account: string): string | null {
  if (WALLET_ADDRESS) {
    if (web3account != WALLET_ADDRESS) {
      window.localStorage.removeItem('WALLET_ACCESS_TOKEN');
      refreshApi();
      return null;
    }
    return WALLET_ADDRESS;
  }

  if (accessToken) {
    const data: any = jwt.decode(accessToken);
    if (data.exp * 1000 + 600 * 1000 > Date.now()) {
      if (web3account == data.wp) {
        WALLET_ADDRESS = data.w;
        return WALLET_ADDRESS;
      }
    }
  }

  if (web3account) {
    window.localStorage.removeItem('WALLET_ACCESS_TOKEN');

    if (!ALREADY_LOGIN_SIGNATURE) {
      ALREADY_LOGIN_SIGNATURE = true;
      loginToWallet(web3account, web3account).catch((err) => {
        ALREADY_LOGIN_SIGNATURE = false;
      });
    }
  }

  return null;
}

export function recoverTxWithdrawSignature(row: any) {
  let parts = row.signature.split('_');

  return {
    functionSignature: row.note,
    r: parts[2],
    s: parts[3],
    v: parseInt(parts[4]),
  };
}

export async function exchangeTokenApiToken(token: string) {
  let response: any = await callTokenApi({
    action: 'dccToken',
    args: {
      dccToken: token,
    },
  });

  if (response) {
    window.localStorage.setItem('WALLET_ACCESS_TOKEN', response.token);
  }
  refreshApi();
}

if (window.localStorage.getItem('jwt'))
  exchangeTokenApiToken(window.localStorage.getItem('jwt') as string);

export class UserRejectionError extends Error {
  constructor() {
    super('User rejected the transaction');
  }
}

// FINISH STATUS: 0=success 1=error 2=userrejected
export interface CallApiProps {
  action: string;
  args: any;
  before?: () => Promise<any>;
  polling?: (data: any) => Promise<boolean>;
  then?: (data: any) => Promise<any>;
  error?: (error: any) => Promise<any>;
  finish?: (status: number, data: any) => Promise<any>;
  customErrorHandler?: boolean;
}

export async function callTokenApi({
  action,
  args,
  before,
  polling,
  then,
  error,
  finish,
  customErrorHandler = false,
}: CallApiProps) {
  try {
    // Get wallet address
    const accounts = await web3.eth.getAccounts();

    if (before) await before();

    // TODO: Hardcode signature request
    try {
      const timestamp = Math.floor(Date.now() / 1000);

      switch (action) {
        case 'txWithdraw':
          args.signature = await requestSignature(
            buildApiWalletSignature([
              'txWithdraw',
              accounts[0],
              args.tokenAddress,
              args.tokenId,
              args.amount,
              timestamp,
            ]),
          );
          args.signatureTimestamp = timestamp;
          break;

        case 'txHarvestReward':
          args.signature = await requestSignature(
            buildApiWalletSignature([
              'txHarvestReward',
              accounts[0],
              args.tokenAddress,
              args.tokenId,
              timestamp,
            ]),
          );
          args.signatureTimestamp = timestamp;
          break;
      }
    } catch (err) {
      throw new UserRejectionError();
    }

    let response = await api.post(
      process.env.REACT_APP_TOKEN_API + '?action=' + action,
      args,
    );

    if (polling) {
      await pollingFn(polling);
    }

    try {
      if (then) await then(response.data);
    } finally {
      if (finish) await finish(0, response.data);
    }

    return response.data;
  } catch (err) {
    if (err instanceof UserRejectionError) {
      if (finish) await finish(2, null);
    } else {
      try {
        if (error) await error(err);
      } finally {
        if (finish) await finish(1, err);
      }
    }

    if (customErrorHandler) {
      throw err;
    } else {
    }
  }
}

export async function checkTxHashPolling(txHash: string) {
  return await pollingFn(async () => {
    let response: any = await callTokenApi({
      action: 'checkTxHash',
      args: {
        txHash,
      },
    });

    return response.valid;
  });
}
