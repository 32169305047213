import React from 'react';

type Props = {
  size?: string;
};

const UserIcon = ({ size }: Props) => {
  const sizeString = size ? `${size}` : '71';
  return (
    <svg
      width={sizeString}
      height={sizeString}
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.2917 0C15.8107 0 0 15.8107 0 35.2917C0 54.7727 15.8107 70.5833 35.2917 70.5833C54.7727 70.5833 70.5833 54.7727 70.5833 35.2917C70.5833 15.8107 54.7727 0 35.2917 0ZM35.2917 10.5875C41.1501 10.5875 45.8792 15.3166 45.8792 21.175C45.8792 27.0334 41.1501 31.7625 35.2917 31.7625C29.4333 31.7625 24.7042 27.0334 24.7042 21.175C24.7042 15.3166 29.4333 10.5875 35.2917 10.5875ZM35.2917 60.7017C26.4688 60.7017 18.6693 56.1843 14.1167 49.3377C14.2225 42.3147 28.2333 38.4679 35.2917 38.4679C42.3147 38.4679 56.3608 42.3147 56.4667 49.3377C51.914 56.1843 44.1146 60.7017 35.2917 60.7017Z"
        fill="white"
      />
    </svg>
  );
};

export default UserIcon;
