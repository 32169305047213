import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  GridItem,
  Image,
  Spacer,
  Text,
  useBoolean,
  Spinner,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { ChessSlot, ChessType } from 'modules/types/Chess';
import ChessCard from 'modules/components/ChessCard';
import { useStores } from 'stores';
import { getAllChessPieces, getChessPieces, patchOnUsedItem } from 'client';
import { parseChesses } from 'common/utils/Chess';

const HomeManage: React.FC<any> = observer((): JSX.Element => {
  const { UserStore, GameStore } = useStores();

  useEffect(() => {
    GameStore.setLoadingMyChesses(true);
    GameStore.setLoadingMyAllChesses(true);

    getChessPieces(UserStore.clientId).then((res: any) => {
      console.log('res:', res);
      const parsedChesses = parseChesses(res.items);
      GameStore.setMyChesses(parsedChesses);
      GameStore.setLoadingMyChesses(false);
    });

    getAllChessPieces(UserStore.clientId).then((res: any) => {
      console.log('res:', res);
      const parsedChesses = parseChesses(res.items);
      GameStore.setMyAllChesses(parsedChesses);
      GameStore.setLoadingMyAllChesses(false);
    });
  }, []);

  const renderTeam = (): Array<JSX.Element> => {
    return [
      ChessSlot.KING,
      ChessSlot.ROOK,
      ChessSlot.KNIGHT,
      ChessSlot.BISHOP_1,
      ChessSlot.BISHOP_2,
    ].map((chessSlot) => {
      const chess = GameStore.myChesses?.units.find(
        (c) => c.slot === chessSlot,
      ) as ChessType;
      return <ChessCard chess={chess} slot={chessSlot} />;
    });
  };

  const renderAllChesses = (): Array<JSX.Element> => {
    return GameStore.myAllChesses
      .filter((chess) => chess.slot === ChessSlot.UNUSED)
      .map((chess) => {
        return <ChessCard chess={chess} slot={chess.slot} />;
      });
  };

  return (
    <Flex
      backgroundColor="#777DED"
      minHeight="100%"
      minWidth="100%"
      align="center"
      alignItems="center"
      justify="center"
      justifyContent="center"
      flexDirection="column"
      overflow="hidden"
    >
      <Box backgroundColor="#777DED" minHeight="100%" minWidth="100%">
        <Box
          paddingY="12"
          paddingX={{ sm: '12', lg: '24' }}
          flexDirection="column"
          overflow="hidden"
        >
          <Box
            maxWidth={{ sm: 'md', md: '2xl', lg: '80%' }}
            marginX={{ sm: 'auto' }}
            width={{ sm: 'full' }}
          >
            <Flex flexDirection="row" alignItems="center">
              <Text
                color="white"
                fontWeight="bold"
                fontSize="48px"
                textShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
              >
                Your team
              </Text>
            </Flex>
            <SimpleGrid marginTop="20px" spacing="20px" minChildWidth="300px">
              {GameStore.isLoadingMyChesses ? (
                <Flex justifyContent="center">
                  <Spinner color="white" size="xl" thickness="4px" mt="20px" />
                </Flex>
              ) : (
                <SimpleGrid
                  marginTop="20px"
                  spacing="20px"
                  minChildWidth="300px"
                >
                  {renderTeam()}
                </SimpleGrid>
              )}
            </SimpleGrid>
            <Text
              color="white"
              fontWeight="bold"
              fontSize="48px"
              textShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
              marginTop="70px"
            >
              All chesses
            </Text>
            {GameStore.isLoadingMyAllChesses ? (
              <Flex justifyContent="center">
                <Spinner color="white" size="xl" thickness="4px" mt="20px" />
              </Flex>
            ) : (
              <SimpleGrid marginTop="40px" spacing="20px" minChildWidth="300px">
                {renderAllChesses()}
              </SimpleGrid>
            )}
          </Box>
        </Box>
      </Box>
      <Spacer />
      <Image width="100%" src="/images/static_tree_background.png" />
    </Flex>
  );
});

export default HomeManage;
