import web3 from './web3';
import ERC20ABI from './ERC20.json';
import BigNumber from 'bignumber.js';

export default class ERC20Token {
  contract: any;
  from: any;

  constructor(from: string, address: string) {
    this.contract = new web3.eth.Contract(ERC20ABI as any, address);
    this.from = from;
  }

  async balanceOf(address: string) {
    return await this.contract.methods.balanceOf(address).call();
  }

  async symbol() {
    return await this.contract.methods.symbol().call();
  }

  async approve(spender: string) {
    console.log('Approve Start');
    let response = await this.contract.methods
      .approve(
        spender,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
      )
      .send({ from: this.from });
    console.log('Approve end', response);
    return response;
  }

  async limitedApprove(spender: string, amount: string | number) {
    return await this.contract.methods
      .approve(spender, amount)
      .send({ from: this.from });
  }

  async allowance(owner: string, spender: string) {
    return await this.contract.methods.allowance(owner, spender).call();
  }

  async checkAllowance(
    owner: string,
    spender: string,
    requiredAmount: string | number,
  ) {
    let allowedAmount = await this.allowance(owner, spender);
    return new BigNumber(allowedAmount).gte(new BigNumber(requiredAmount));
  }

  async approveSmart(spender: string, amount: string | number) {
    if (await this.checkAllowance(this.from, spender, amount)) {
      return true;
    }

    return await this.approve(spender);
  }

  async totalSupply() {
    return await this.contract.methods.totalSupply().call();
  }

  async totalBurn() {
    return await this.contract.methods.totalBurn().call();
  }

  async upgrade() {
    return await this.contract.methods.upgrade().send({ from: this.from });
  }
}
