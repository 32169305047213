import { types, cast, Instance } from 'mobx-state-tree';

const AppModel = types
  .model('AppModel', {
    isConnectingWallet: types.boolean,
  })
  .actions((self) => {
    // GETTER
    const getIsConnectingWallet = (): boolean => {
      return self.isConnectingWallet;
    };

    // SETTER
    const setIsConnectingWallet = (isConnectingWallet: boolean) => {
      self.isConnectingWallet = isConnectingWallet;
    };

    return {
      getIsConnectingWallet,

      setIsConnectingWallet,
    };
  })
  .create({
    isConnectingWallet: false,
  });

export default AppModel;
