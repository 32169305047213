import { useDisclosure } from '@chakra-ui/hooks';
import { Image } from '@chakra-ui/image';
import { Text } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Button, Flex, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'stores';

function MatchResultDialog({
  open = false,
  win = false,
  isLoad = false,
}: {
  open?: boolean;
  win?: boolean;
  isLoad?: boolean;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const { GameStore } = useStores();

  useEffect(() => {
    if (open) onOpen();
    else onClose();
  }, [open]);

  const backToHome = () => {
    GameStore.reset();
    history.push('/');
  };

  return (
    <>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p="40px"
          >
            {isLoad ? (
              <Spinner size="xl" thickness="4px" mb="20px" />
            ) : (
              <Image
                width="150px"
                pb="10px"
                src="/images/home_chess_logo.png"
              />
            )}
            <Text fontWeight="bold" fontSize="32px" textAlign="center">
              {win ? 'Congratulations, you won' : 'You lost'}
            </Text>
            <Text fontWeight="medium" mt="10px">
              +10 FSH
            </Text>
            <Button mt="20px" onClick={() => backToHome()}>
              Back to home
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MatchResultDialog;
