import { ChessType, ChessClass, ChessSlot } from '../../modules/types/Chess';

export const rook: ChessType = {
  name: 'Blue rook',
  id: 0,
  img: '/images/rook.png',
  chessType: ChessClass.Rook,
  maxHp: 30,
  curHp: 30,
  atk: 20,
  def: 10,
  spd: 40,
  slot: ChessSlot.ROOK,
  price: 0,
  saleListed: false,
  nftId: '0',
  playerId: 0,
  skill:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
};

export const knight: ChessType = {
  name: 'Blue knight',
  id: 1,
  img: '/images/knight.png',
  chessType: ChessClass.Knight,
  maxHp: 30,
  curHp: 30,
  atk: 20,
  def: 10,
  spd: 40,
  slot: ChessSlot.KNIGHT,
  price: 0,
  saleListed: false,
  nftId: '1',
  playerId: 0,
  skill:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
};

export const king: ChessType = {
  name: 'Blue king',
  id: 2,
  img: '/images/king.png',
  chessType: ChessClass.King,
  maxHp: 30,
  curHp: 30,
  atk: 20,
  def: 10,
  spd: 40,
  slot: ChessSlot.KING,
  price: 0,
  saleListed: false,
  nftId: '2',
  playerId: 0,
  skill:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
};

export const bishop: ChessType = {
  name: 'Blue bishop',
  id: 3,
  img: '/images/bishop.png',
  chessType: ChessClass.Bishop,
  maxHp: 30,
  curHp: 30,
  atk: 20,
  def: 10,
  spd: 40,
  slot: ChessSlot.BISHOP_1,
  price: 0,
  saleListed: false,
  nftId: '3',
  playerId: 0,
  skill:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
};

export const bishop2: ChessType = {
  name: 'Blue bishop',
  id: 4,
  img: '/images/bishop.png',
  chessType: ChessClass.Bishop,
  maxHp: 30,
  curHp: 30,
  atk: 20,
  def: 10,
  spd: 40,
  slot: ChessSlot.BISHOP_2,
  price: 0,
  saleListed: false,
  nftId: '4',
  playerId: 0,
  skill:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
};

export const rook2: ChessType = {
  name: 'Blue rook',
  id: 5,
  img: '/images/rook.png',
  chessType: ChessClass.Rook,
  maxHp: 30,
  curHp: 30,
  atk: 20,
  def: 10,
  spd: 40,
  slot: ChessSlot.ROOK,
  price: 0,
  saleListed: false,
  nftId: '5',
  playerId: 0,
  skill:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
};

export const knight2: ChessType = {
  name: 'Blue knight',
  id: 6,
  img: '/images/knight.png',
  chessType: ChessClass.Knight,
  maxHp: 30,
  curHp: 30,
  atk: 20,
  def: 10,
  spd: 40,
  slot: ChessSlot.KNIGHT,
  price: 0,
  saleListed: false,
  nftId: '6',
  playerId: 0,
  skill:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
};

export const king2: ChessType = {
  name: 'Blue king',
  id: 7,
  img: '/images/king.png',
  chessType: ChessClass.King,
  maxHp: 30,
  curHp: 30,
  atk: 20,
  def: 10,
  spd: 40,
  slot: ChessSlot.KING,
  price: 0,
  saleListed: false,
  nftId: '7',
  playerId: 0,
  skill:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
};

export const bishop3: ChessType = {
  name: 'Blue bishop',
  id: 8,
  img: '/images/bishop.png',
  chessType: ChessClass.Bishop,
  maxHp: 30,
  curHp: 30,
  atk: 20,
  def: 10,
  spd: 40,
  slot: ChessSlot.BISHOP_1,
  price: 0,
  saleListed: false,
  nftId: '8',
  playerId: 0,
  skill:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
};

export const bishop4: ChessType = {
  name: 'Blue bishop',
  id: 9,
  img: '/images/bishop.png',
  chessType: ChessClass.Bishop,
  maxHp: 30,
  curHp: 30,
  atk: 20,
  def: 10,
  spd: 40,
  slot: ChessSlot.BISHOP_2,
  price: 0,
  saleListed: false,
  nftId: '9',
  playerId: 0,
  skill:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
};

export const chesses: ChessType[] = [king, rook, knight, bishop, bishop2];
export const chesses2: ChessType[] = [king2, rook2, knight2, bishop3, bishop4];
