export class PollingTimedOutError extends Error {
  constructor() {
    super("Status check timed out");
  }
}

export function pollingFn(polling: (...any: any) => Promise<boolean>) {
  let pollingCount = 0;

  return new Promise<void>((resolve, reject) => {
    const interval = setInterval(async () => {
      pollingCount++;
      if (pollingCount >= 100) {
        clearInterval(interval);
        reject(new PollingTimedOutError());
        return;
      }

      const result = await polling();

      if (result) {
        clearInterval(interval);
        resolve();
      }
    }, 3000);
  })

}
