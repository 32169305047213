import React, { useState } from 'react';
import { Button, useColorModeValue as mode, useToast } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { connectWallet, getAccount } from 'common/blockchain';
import { useStores } from 'stores';
import { getJWTToken } from 'common/utils/token';

const ConnectWalletButton: React.FC<any> = observer((props): JSX.Element => {
  const { AppStore } = useStores();
  const { UserStore } = useStores();
  const toast = useToast();
  return (
    <Button
      isLoading={AppStore.isConnectingWallet}
      loadingText="Connecting..."
      onClick={async () => {
        AppStore.setIsConnectingWallet(true);
        await connectWallet(
          (error: string) => {
            toast({
              title: `${error}`,
              status: 'error',
              isClosable: true,
            });
          },
          () => {
            AppStore.setIsConnectingWallet(false);
          },
        );
        UserStore.setClientId((await getAccount()) || '');
        UserStore.setJWT(getJWTToken() || '');
        console.log('jwt', getJWTToken(), UserStore.getJWT());
      }}
      variant="roundsolid"
      size="solidxl"
      {...props}
    >
      Connect to wallet
    </Button>
  );
});

export default ConnectWalletButton;
