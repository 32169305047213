import { getAccount } from 'common/blockchain';
import { getJWTToken } from 'common/utils/token';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useStores } from 'stores';

type Props = {
  children: any;
  requireNoAuth?: boolean;
  redirectPath?: string;
};

const RequireAuth = observer(
  ({ requireNoAuth = false, redirectPath, children }: Props) => {
    const { UserStore } = useStores();
    const isAuth = !!UserStore.jwt && !!UserStore.clientId;

    useEffect(() => {
      const initAccount = async () => {
        const account = await getAccount();
        if (account) UserStore.setClientId(account);
        const jwt = getJWTToken();
        if (jwt) UserStore.setJWT(jwt);
      };
      initAccount();
    }, []);

    return isAuth !== requireNoAuth ? (
      children
    ) : (
      <Redirect to={redirectPath || '/'} />
    );
  },
);

export default RequireAuth;
