import {
  Box,
  Button,
  Flex,
  Image,
  SimpleGrid,
  Tag,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { ChessSlot, ChessType } from '../../types/Chess';
import ChessImage from '../ChessImage';
import ChessInfoDialog from '../ChessInfoDialog';
import './style.css';

type Props = {
  chess: ChessType;
  slot: ChessSlot;
  listMode?: boolean;
};

const ChessCard = ({ chess, slot, listMode = false }: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <Box
      backgroundColor="white"
      boxShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
      rounded="2xl"
      width="300px"
      className="cardBox"
      onClick={() => setOpen(true)}
    >
      {!chess ? (
        <Flex
          alignItems="center"
          margin="20px"
          minHeight="300px"
          justifyContent="center"
        >
          <Text
            fontWeight="bold"
            fontSize="40px"
            color="#2036616e"
            textAlign="center"
          >
            Unslotted {`${slot}`}
          </Text>
        </Flex>
      ) : (
        <Flex
          align="center"
          alignItems="center"
          flexDirection="column"
          margin="20px"
        >
          <ChessInfoDialog
            open={open}
            chess={chess}
            onCloseDialog={() => {
              setOpen(false);
            }}
            listedMode={listMode}
          />
          <Text fontWeight="bold" fontSize="18px" color="#203661">
            #{chess.id}
            {chess.saleListed && !listMode && (
              <Tag colorScheme="red" ml="2px">
                Listed on marketplace
              </Tag>
            )}
          </Text>
          <Text
            fontWeight="bold"
            fontSize="40px"
            color="#203661"
            isTruncated
            maxWidth="100%"
          >
            {chess.name}
          </Text>
          {/* <Image src={chess.img} maxHeight="160px" marginTop="20px" /> */}
          <ChessImage chessId={chess.id} chessType={chess.chessType} />
          <SimpleGrid columns={2} row={2} spacingX="60px" marginTop="20px">
            <Text fontWeight="medium" fontSize="18px" color="#203661">
              HP : {chess.maxHp}
            </Text>
            <Text fontWeight="medium" fontSize="18px" color="#203661">
              ATK : {chess.atk}
            </Text>
            <Text fontWeight="medium" fontSize="18px" color="#203661">
              SPD : {chess.spd}
            </Text>
            <Text fontWeight="medium" fontSize="18px" color="#203661">
              DEF : {chess.def}
            </Text>
          </SimpleGrid>
          {listMode && (
            <Text
              fontWeight="bold"
              fontSize="30px"
              mt="8px"
              bgGradient="linear(to-l, #D665FF, #4C6FFF)"
              bgClip="text"
            >
              {chess.price} FSH
            </Text>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default ChessCard;
