import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useIsMount } from 'common/hooks';
import { ChessType } from '../../types/Chess';
import './style.css';

type Props = {
  chess: ChessType;
  isEnemy: boolean;
};

const Chess = ({ chess, isEnemy }: Props) => {
  const [shake, setShake] = useState(false);
  const isFirstTimeMount = useIsMount();
  const hpPercentage = (chess.curHp / chess.maxHp) * 100;
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };
  let hpColor;
  if (!isEnemy) {
    hpColor = hpPercentage > 50 ? '#FF8C36' : '#E83D21';
  } else {
    hpColor = hpPercentage > 50 ? '#816bff' : '#a05bfa';
  }

  useEffect(() => {
    if (chess.curHp !== chess.maxHp && !isFirstTimeMount) {
      setShake(true);
      setTimeout(() => setShake(false), 1200);
    }
  }, [chess.curHp]);
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={variants}
      className="chess-container"
    >
      <div className="chess-status-bar">
        <div
          className="chess-hp"
          style={{
            width: `${hpPercentage}%`,
            backgroundColor: hpColor,
          }}
        />
      </div>
      <img
        className={`chess-img ${shake ? 'shake' : ''}`}
        src={chess.img}
        alt={chess.name}
      />
    </motion.div>
  );
};

export default Chess;
