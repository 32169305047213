import { Flex, Image, Text, Box } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { GameState } from 'stores/Game';
import { useStores } from '../../../stores';

const InfoBar = observer(() => {
  const { GameStore } = useStores();

  const activeUnit = GameStore.showingInfoUnit?.unit;

  const hpPercentage =
    ((activeUnit?.curHp || 1) / (activeUnit?.maxHp || 1)) * 100;
  const hpColor = hpPercentage > 50 ? '#FF8C36' : '#E83D21';

  const { gameState } = GameStore;
  let guidingText;

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [GameStore.history]);

  switch (gameState) {
    case GameState.CONNECTING:
      guidingText = `Connecting...`;
      break;
    case GameState.PLACING:
      guidingText = `Place your chess on the board: ${GameStore.myChesses?.placing}/5`;
      break;
    case GameState.MOVE_OR_ATTACK:
      guidingText = `Move your chess or attack: ${GameStore.remainingTime}s`;
      break;
    case GameState.MOVE_ONLY:
      guidingText = `Move your chess: ${GameStore.remainingTime}s`;
      break;
    case GameState.ATTACK_ONLY:
      guidingText = 'Attack enemy:';
      break;
    case GameState.ENEMY_PLACING:
      guidingText = 'Waiting for your opponent to place the chesses...';
      break;
    case GameState.ENEMY_FINISHED_PLACING:
      guidingText = `Place your chess on the board: ${GameStore.myChesses?.placing}/5`;
      break;
    case GameState.ENEMY_TURN:
      guidingText = `Enemy's turn...`;
      break;
    default:
      guidingText = '';
  }
  return (
    <Flex
      width="320px"
      minHeight="1vh"
      flexDirection="column"
      ml={8}
      color="#203661"
    >
      <Flex
        height="200px"
        width="100%"
        backgroundColor="white"
        borderRadius="16px"
        boxShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
        mb={6}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        p="16px"
      >
        <Text fontWeight="bold" fontSize="24px">
          {guidingText}
        </Text>
      </Flex>
      <Flex
        minHeight="200px"
        width="100%"
        backgroundColor="white"
        borderRadius="16px"
        mb={6}
        boxShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
        flexDirection="row"
      >
        {!activeUnit ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            p="16px"
          >
            <Text fontWeight="bold" fontSize="24px">
              Select a chess to see info
            </Text>
          </Flex>
        ) : (
          <Flex width="100%" height="100%">
            <Flex width="40%" p="16px" pt="42px" justifyContent="center">
              <Image src={activeUnit.img} maxHeight="75px" />
            </Flex>
            <Flex width="60%" pt="16px" pb="24px" flexDirection="column">
              <Text fontSize="16px">{`#${activeUnit.id}`}</Text>
              <Text
                fontSize="26px"
                fontWeight="bold"
              >{`${activeUnit.name}`}</Text>
              <Text
                fontSize="16px"
                fontWeight="medium"
              >{`HP: ${activeUnit.curHp}/${activeUnit.maxHp}`}</Text>
              <Box
                height="4px"
                borderRadius="3px"
                backgroundColor="#203661"
                width="80%"
                mb="8px"
              >
                <Box
                  height="100%"
                  borderRadius="3px"
                  backgroundColor={hpColor}
                  width={`${hpPercentage}%`}
                />
              </Box>
              <Text
                fontSize="16px"
                fontWeight="medium"
                lineHeight="20px"
              >{`ATK: ${activeUnit.atk}`}</Text>
              <Text
                fontSize="16px"
                fontWeight="medium"
                lineHeight="20px"
              >{`DEF: ${activeUnit.def}`}</Text>
              <Text
                fontSize="16px"
                fontWeight="medium"
                lineHeight="20px"
              >{`SPD: ${activeUnit.spd}`}</Text>
              {/* <Text fontSize="20px" fontWeight="bold" mt="12px">
                Ability:
              </Text>
              <Text
                fontSize="16px"
                fontWeight="medium"
                lineHeight="20px"
              >{`${activeUnit.skill}`}</Text> */}
            </Flex>
          </Flex>
        )}
      </Flex>
      <Flex
        height="250px"
        width="100%"
        backgroundColor="white"
        borderRadius="16px"
        boxShadow="7px 7px 4px rgba(0, 0, 0, 0.25);"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        p="16px"
      >
        {GameStore.history.length > 0 ? (
          <Flex height="95%" width="100%" flexDir="column" overflow="auto">
            {GameStore.history.map((his, idx) => {
              return (
                <Text
                  fontSize="16px"
                  fontWeight="medium"
                  backgroundColor={
                    his.message.includes('You') ? '#ffebd6' : '#d6e6ff'
                  }
                  mb="4px"
                  pl="4px"
                  borderRadius="3px"
                  onMouseEnter={() =>
                    GameStore.setHighLightHist(his.src, his.dest)
                  }
                  onMouseLeave={() => GameStore.setHighLightHist('', '')}
                >
                  {his.message.includes('attack') && '🗡️'}
                  {his.message}
                </Text>
              );
            })}
            <div ref={messagesEndRef} />
          </Flex>
        ) : (
          <Text fontWeight="bold" fontSize="24px" textAlign="center">
            Game history log will be shown here.
          </Text>
        )}
      </Flex>
    </Flex>
  );
});

export default InfoBar;
