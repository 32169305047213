import { useDisclosure } from '@chakra-ui/hooks';
import { Image } from '@chakra-ui/image';
import { Text } from '@chakra-ui/layout';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Button, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';

function GameStateDialog({
  open = false,
  guidingText = '',
  isLoad = false,
  onSetClose,
}: {
  open?: boolean;
  guidingText?: string;
  isLoad?: boolean;
  onSetClose?: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (open) onOpen();
    else onClose();
  }, [open]);

  const onCancelMatchFinding = () => {
    console.log('exit match finding');
    onSetClose?.();
  };

  return (
    <>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
        motionPreset="slideInBottom"
        size="sm"
        onEsc={onCancelMatchFinding}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody
            minHeight="250px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {isLoad ? (
              <Spinner size="xl" thickness="4px" mb="20px" />
            ) : (
              <Image
                width="150px"
                pb="10px"
                src="/images/home_chess_logo.png"
              />
            )}
            <Text fontWeight="bold" fontSize="32px" textAlign="center">
              {guidingText}
            </Text>
            {isLoad && (
              <Button mt="30px" onClick={onCancelMatchFinding}>
                Cancel
              </Button>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default GameStateDialog;
