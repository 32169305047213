import { ChessClass } from 'modules/types/Chess';
import React from 'react';

type Props = {
  chessType: ChessClass;
  chessId: number;
};

function rgb2hsv(r: number, g: number, b: number) {
  const v = Math.max(r, g, b);
  const c = v - Math.min(r, g, b);
  const h =
    c && (v === r ? (g - b) / c : v === g ? 2 + (b - r) / c : 4 + (r - g) / c);
  return [60 * (h < 0 ? h + 6 : h), v && c / v, v];
}

function hsv2rgb(h: number, s: number, v: number) {
  const f = (n: number, k = (n + h / 60) % 6) =>
    v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
  return [f(5), f(3), f(1)];
}

function varyColor(colors: string[], id: number) {
  return colors.map((color) => {
    // #112233
    const [h, s, v] = rgb2hsv(
      parseInt(color.slice(1, 3), 16) / 255,
      parseInt(color.slice(3, 5), 16) / 255,
      parseInt(color.slice(5), 16) / 255,
    );
    const result = hsv2rgb((h + id / 3) % 360, s, v);
    const newCode = result
      .map((c) => {
        return `0${Math.round(c * 255).toString(16)}`.slice(-2);
      })
      .join('');
    return `#${newCode}`;
  });
}

const ChessImage = ({ chessType, chessId }: Props) => {
  //Example for KingSVG = "Y60-G120-W100-V60" => Yellow  Green Brown Violet
  let colorCode: string;
  if (chessType === ChessClass.King) colorCode = 'Y60-G120-W100-V60';
  else if (chessType === ChessClass.Knight) colorCode = 'R100-V50-V100-NSW';
  else if (chessType === ChessClass.Rook) colorCode = 'Y60-RY-V100';
  else colorCode = 'W-BSW-W-BH';
  const code = colorCode.split('-');
  const m = {
    Y60: ['#FFCE00', '#FFA63B'],
    G120: ['#1DBC77', '#1DCC87', '#FFCE00'],
    W100: ['#D37026'],
    V60: ['#6C70E6', '#5C5EDC', '#777DED'],
    V120: ['#203661'], //rook
    V100: ['#454DD8'], //rook
    V50: ['#777DED'], //knight
    V80: ['#5C5EDC'], //knight
    R100: ['#E83D21'], //knight
    RY: ['#FEC01F'], //rook
    W: ['#FFFFFF'], //bishop
    G100: ['#1DCCB6'],
    G90: ['#97E8DE'],
    BW: ['#37D8FF'],
    BH: ['#1DCCB6', '#97E8DE', '#1DCC87'],
    NSW: ['#97E8DE', '#1DCCB6', '#37D8FF'], //knight
    BSW: ['#37D8FF', '#FFFFFF'], //bishop
  };
  const color = code.map((e) => {
    switch (e) {
      case 'Y60':
        return varyColor(m.Y60, chessId);
      case 'G120':
        return varyColor(m.G120, chessId);
      case 'W100':
        return varyColor(m.W100, chessId);
      case 'V60':
        return varyColor(m.V60, chessId);
      case 'V120':
        return varyColor(m.V120, chessId);
      case 'V100':
        return varyColor(m.V100, chessId);
      case 'V50':
        return varyColor(m.V50, chessId);
      case 'V80':
        return varyColor(m.V80, chessId);
      case 'R100':
        return varyColor(m.R100, chessId);
      case 'W':
        return varyColor(m.W, chessId);
      case 'G100':
        return varyColor(m.G100, chessId);
      case 'G90':
        return varyColor(m.G90, chessId);
      case 'RY':
        return varyColor(m.RY, chessId);
      case 'BW':
        return varyColor(m.BW, chessId);
      case 'BH':
        return varyColor(m.BH, chessId);
      case 'NSW':
        return varyColor(m.NSW, chessId);
      case 'BSW':
        return varyColor(m.BSW, chessId);
      default:
        return ['color code crash'];
    }
  });
  let render = <></>;
  if (chessType === ChessClass.King) {
    render = (
      <svg
        width="297"
        height="160"
        viewBox="0 0 297 285"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_523_2757)">
          <rect
            width="36.2515"
            height="9.32052"
            transform="matrix(0.879343 -0.476189 -0.476189 -0.879343 25.8121 135.15)"
            fill="#C4C4C4"
          />
          <ellipse
            rx="8.0559"
            ry="10.5915"
            transform="matrix(0.879343 -0.476189 -0.476189 -0.879343 25.4097 96.3424)"
            fill="#C4C4C4"
          />
          <rect
            width="9.39855"
            height="27.9615"
            transform="matrix(0.879343 -0.476189 -0.476189 -0.879343 35.1978 124.286)"
            fill="#C4C4C4"
          />
          <path
            d="M70.2063 227.702L81.2924 199.537L41.7509 126.518L25.8122 135.15L70.2063 227.702Z"
            fill={color[3][0]}
          />
          <path
            d="M110.939 205.645L81.2924 199.537L41.7509 126.518L57.6897 117.887L110.939 205.645Z"
            fill={color[3][1]}
          />
          <path
            d="M70.2063 227.702L105.501 244.242L81.2924 199.537L70.2063 227.702Z"
            fill={color[3][2]}
          />
          <path
            d="M110.939 205.645L105.501 244.242L81.2924 199.537L110.939 205.645Z"
            fill={color[3][0]}
          />
          <rect
            width="36.2515"
            height="9.32052"
            transform="matrix(0.879343 -0.476189 -0.476189 -0.879343 25.8121 135.15)"
            fill="#C4C4C4"
          />
          <ellipse
            rx="8.0559"
            ry="10.5915"
            transform="matrix(0.879343 -0.476189 -0.476189 -0.879343 25.4097 96.3424)"
            fill="#C4C4C4"
          />
          <rect
            width="9.39855"
            height="27.9615"
            transform="matrix(0.879343 -0.476189 -0.476189 -0.879343 35.1978 124.286)"
            fill="#C4C4C4"
          />
          <path
            d="M70.2063 227.702L81.2924 199.537L41.7509 126.518L25.8122 135.15L70.2063 227.702Z"
            fill={color[3][0]}
          />
          <path
            d="M110.939 205.645L81.2924 199.537L41.7509 126.518L57.6897 117.887L110.939 205.645Z"
            fill={color[3][1]}
          />
          <path
            d="M70.2063 227.702L105.501 244.242L81.2924 199.537L70.2063 227.702Z"
            fill={color[3][2]}
          />
          <path
            d="M110.939 205.645L105.501 244.242L81.2924 199.537L110.939 205.645Z"
            fill={color[3][0]}
          />
          <circle cx="69.5" cy="186.5" r="21.5" fill={color[2][0]} />
          <circle cx="158.5" cy="181.5" r="21.5" fill={color[2][0]} />
          <circle cx="142.5" cy="186.5" r="21.5" fill={color[2][0]} />
          <circle cx="105.5" cy="187.5" r="21.5" fill={color[2][0]} />
          <circle cx="188.5" cy="187.5" r="21.5" fill={color[2][0]} />
          <circle cx="161.5" cy="208.5" r="21.5" fill={color[2][0]} />
          <circle cx="129.5" cy="217.5" r="21.5" fill={color[2][0]} />
          <circle cx="99.5" cy="207.5" r="21.5" fill={color[2][0]} />
          <rect x="59" y="111" width="140" height="70" fill={color[2][0]} />
          <rect x="63" y="107" width="132" height="70" fill="#FFC692" />
          <ellipse
            cx="95.5588"
            cy="114.267"
            rx="37.483"
            ry="16"
            transform="rotate(13.651 95.5588 114.267)"
            fill={color[2][0]}
          />
          <ellipse
            rx="37.483"
            ry="16"
            transform="matrix(-0.971751 0.236007 0.236007 0.971751 162.2 114.394)"
            fill={color[2][0]}
          />
          <path
            d="M84 34.9308C84 30.9134 89.2593 29.4032 91.3907 32.8087L117.407 74.3779C119.075 77.042 117.16 80.5 114.017 80.5H88C85.7909 80.5 84 78.7091 84 76.5L84 34.9308Z"
            fill={color[0][1]}
          />
          <path
            d="M174.239 34.9308C174.239 30.9134 168.98 29.4032 166.848 32.8087L140.832 74.3779C139.164 77.042 141.079 80.5 144.222 80.5H170.239C172.448 80.5 174.239 78.7091 174.239 76.5L174.239 34.9308Z"
            fill={color[0][1]}
          />
          <path
            d="M59.7561 46.3031C59.7561 40.6098 66.9435 38.1175 70.4681 42.5886L113.586 97.2855C116.688 101.221 113.885 107 108.874 107H65.7561C62.4424 107 59.7561 104.314 59.7561 101L59.7561 46.3031Z"
            fill={color[0][0]}
          />
          <path
            d="M197.488 46.3031C197.488 40.6098 190.3 38.1175 186.776 42.5886L143.658 97.2855C140.556 101.221 143.359 107 148.37 107H191.488C194.802 107 197.488 104.314 197.488 101L197.488 46.3031Z"
            fill={color[0][0]}
          />
          <path
            d="M123.361 15.5194C125.275 10.2516 132.725 10.2516 134.639 15.5194L165.046 99.2009C166.468 103.114 163.57 107.25 159.407 107.25H98.5929C94.4297 107.25 91.5318 103.114 92.9536 99.2009L123.361 15.5194Z"
            fill={color[0][0]}
          />
          <path
            d="M128.469 124.854C128.816 124.949 129.184 124.949 129.531 124.854L190.955 107.928C193.201 107.309 192.754 104 190.423 104H67.5765C65.2462 104 64.7987 107.309 67.0452 107.928L128.469 124.854Z"
            fill={color[0][0]}
          />
          <path
            d="M125.633 81.3003C126.503 78.4786 130.497 78.4786 131.367 81.3003L132.001 83.3579C132.389 84.6159 133.552 85.4742 134.868 85.4742H137.243C140.111 85.4742 141.344 89.1123 139.067 90.856L136.879 92.5319C135.882 93.295 135.466 94.5979 135.836 95.7974L136.607 98.2987C137.467 101.091 134.235 103.341 131.916 101.564L130.324 100.345C129.248 99.5209 127.752 99.5209 126.676 100.345L125.084 101.564C122.765 103.341 119.533 101.091 120.393 98.2987L121.164 95.7974C121.534 94.5979 121.118 93.295 120.121 92.5319L117.933 90.856C115.656 89.1123 116.889 85.4742 119.757 85.4742H122.132C123.448 85.4742 124.611 84.6159 124.999 83.3579L125.633 81.3003Z"
            fill="#6C70E6"
          />
          <ellipse cx="109" cy="148" rx="6" ry="8" fill="#CE996E" />
          <ellipse cx="151" cy="148" rx="6" ry="8" fill="#CE996E" />
          <ellipse cx="131" cy="173" rx="6" ry="8" fill="#FF8C36" />
          <path
            d="M130.5 208C143.479 208 154 197.255 154 184H107C107 197.255 117.521 208 130.5 208Z"
            fill="white"
          />
          <path
            d="M130.5 208C143.479 208 154 197.255 154 184H107C107 197.255 117.521 208 130.5 208Z"
            fill="white"
          />
          <path
            d="M185.919 167.497C187.428 160.645 194.467 156.543 201.172 158.609L261.324 177.143C268.028 179.209 271.538 186.561 268.929 193.074L246.167 249.89C243.844 255.687 237.462 258.734 231.494 256.895L180.944 241.32C174.975 239.481 171.415 233.371 172.758 227.271L185.919 167.497Z"
            fill={color[1][0]}
          />
          <path
            d="M205.498 263.934C203.291 264.318 201.034 263.622 199.425 262.063L175.475 238.837C170.193 233.714 175.377 224.955 182.409 227.122L239.229 244.629C246.26 246.795 245.617 256.953 238.367 258.215L205.498 263.934Z"
            fill={color[1][0]}
          />
          <path
            d="M191.643 182.02C193.151 175.168 200.191 171.067 206.895 173.132L248.503 185.953C255.208 188.018 258.718 195.371 256.109 201.883L240.262 241.439C237.939 247.236 231.558 250.283 225.589 248.444L190.666 237.683C184.697 235.844 181.137 229.734 182.48 223.635L191.643 182.02Z"
            fill={color[1][1]}
          />
          <path
            d="M208.33 254.524C206.123 254.908 203.865 254.213 202.257 252.653L188.651 239.458C183.369 234.336 188.553 225.577 195.585 227.743L227.864 237.689C234.896 239.856 234.252 250.014 227.003 251.275L208.33 254.524Z"
            fill={color[1][1]}
          />
          <path
            d="M217.631 200.372C219.258 198.732 222.049 199.469 222.655 201.697L228.338 222.589C228.945 224.817 226.911 226.866 224.678 226.277L203.744 220.753C201.511 220.164 200.753 217.378 202.38 215.739L217.631 200.372Z"
            fill={color[1][2]}
          />
        </g>
        <defs>
          <filter
            id="filter0_d_523_2757"
            x="16.7131"
            y="11.5685"
            width="261.081"
            height="260.469"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_523_2757"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_523_2757"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  } else if (chessType === ChessClass.Rook) {
    render = (
      <svg
        width="180"
        height="165"
        viewBox="0 0 180 233"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_600_2595)">
          <path
            d="M43 69H140C124.232 133.366 127.321 165.667 140 221H43C58.1168 164.861 59.8154 131.942 43 69Z"
            fill={color[1][0]}
          />
        </g>
        <g filter="url(#filter1_d_600_2595)">
          <path
            d="M5 26.0701L19.5557 68.8785H89.8627V1L59.9273 13.5351L66.5537 44.1054C67.209 47.1289 66.1574 50.2687 63.8131 52.2876L63.0723 52.9255C61.2902 54.4602 58.6327 54.3852 56.94 52.7526C54.4614 50.362 53.0614 47.0665 53.0614 43.6229V20.1574L49.6628 19.1932C36.8389 15.5553 23.1151 16.8833 11.2266 22.9124L5 26.0701Z"
            fill={color[0][0]}
            stroke={color[0][0]}
          />
          <path
            d="M175 26.0701L160.444 68.8785H90.1373V1L120.073 13.5351L113.446 44.1054C112.791 47.1289 113.843 50.2687 116.187 52.2876L116.928 52.9255C118.71 54.4602 121.367 54.3852 123.06 52.7526C125.539 50.362 126.939 47.0665 126.939 43.6229V20.1574L130.337 19.1932C143.161 15.5553 156.885 16.8833 168.773 22.9124L175 26.0701Z"
            fill={color[0][0]}
            stroke={color[0][0]}
          />
        </g>
        <g filter="url(#filter2_d_600_2595)">
          <rect x="30" y="199" width="124" height="26" fill="#FEC01F" />
        </g>
        <path
          d="M72 108.76C77.041 104.932 79.6412 105.231 84 108.76V125H72V108.76Z"
          fill="#203661"
        />
        <path
          d="M103 108.76C108.041 104.932 110.641 105.231 115 108.76V125H103V108.76Z"
          fill="#203661"
        />
        <path d="M52.733 177H131.5L133 186H51L52.733 177Z" fill="white" />
        <g filter="url(#filter3_d_600_2595)">
          <path
            d="M65.6056 197.562L121.713 180.939L127.891 197.353L74.6013 221.46L65.6056 197.562Z"
            fill={color[2][0]}
          />
          <circle
            cx="69.447"
            cy="209.758"
            r="12.7676"
            transform="rotate(-20.6271 69.447 209.758)"
            fill={color[2][0]}
          />
          <circle cx="92.5" cy="209.5" r="12.5" fill="#203661" />
          <circle cx="92.5" cy="209.5" r="8.5" fill="#2E4778" />
        </g>
        <defs>
          <filter
            id="filter0_d_600_2595"
            x="39"
            y="69"
            width="105"
            height="160"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_600_2595"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_600_2595"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_600_2595"
            x="0.386963"
            y="0.248566"
            width="179.226"
            height="77.13"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_600_2595"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_600_2595"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_600_2595"
            x="26"
            y="199"
            width="132"
            height="34"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_600_2595"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_600_2595"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_600_2595"
            x="56.6759"
            y="180.939"
            width="79.2155"
            height="49.5896"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_600_2595"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_600_2595"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  } else if (chessType === ChessClass.Bishop) {
    render = (
      <svg
        width="233"
        height="150"
        viewBox="0 0 233 262"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_600_2596)">
          <path
            d="M23.1566 180.788C24.3299 171.304 27.4045 168.859 37.4707 169.942L84.7254 232.311C82.3676 240.243 79.4033 242.811 70.4113 243.156L23.1566 180.788Z"
            fill={color[1][0]}
          />
          <path
            d="M31.7414 224.348C21.9947 222.933 15.1368 215.822 16.7132 204.937L22.3213 205.607L62.0151 176.159L62.8802 170.488C72.6125 172.347 78.1345 180.973 77.4951 190.707L72.2953 191.302L33.3057 220.843L31.7414 224.348Z"
            fill={color[1][0]}
          />
        </g>
        <path
          d="M35.0372 190.062C35.6409 185.182 37.2228 183.924 42.4019 184.482L66.7146 216.57C65.5016 220.651 63.9764 221.973 59.35 222.15L35.0372 190.062Z"
          fill={color[1][1]}
        />
        <path
          d="M39.4544 212.474C34.4397 211.745 30.9112 208.087 31.7223 202.486L34.6077 202.831L55.0303 187.68L55.4754 184.762C60.4827 185.719 63.3238 190.157 62.9948 195.165L60.3195 195.471L40.2592 210.67L39.4544 212.474Z"
          fill={color[1][1]}
        />
        <g filter="url(#filter1_d_600_2596)">
          <ellipse cx="66" cy="174" rx="12" ry="13" fill="#FFC692" />
          <ellipse cx="66.5" cy="176.5" rx="9.5" ry="10.5" fill="#FFA859" />
          <ellipse
            rx="12"
            ry="13"
            transform="matrix(-1 0 0 1 198 174)"
            fill="#FFC692"
          />
          <ellipse
            rx="9.5"
            ry="10.5"
            transform="matrix(-1 0 0 1 197.5 176.5)"
            fill="#FFA859"
          />
          <g>
            <circle cx="73.5" cy="195.5" r="16.5" fill={color[2][0]} />
            <circle cx="190.5" cy="196.5" r="16.5" fill={color[2][0]} />
            <circle cx="130.5" cy="219.5" r="23.5" fill={color[2][0]} />
            <circle cx="133" cy="180" r="46" fill={color[2][0]} />
            <circle cx="92.5" cy="212.5" r="16.5" fill={color[2][0]} />
            <circle cx="165.5" cy="212.5" r="16.5" fill={color[2][0]} />
          </g>
          <rect
            x="67"
            y="126"
            width="130"
            height="68"
            fill="#FFC692"
            stroke={color[2][0]}
            strokeWidth="2"
          />
          <path
            d="M216.81 139.762L200.892 160.714C198.353 164.056 193.998 165.457 189.986 164.222L173 158.991L194.555 89L217.352 123.588C220.621 128.549 220.404 135.032 216.81 139.762Z"
            fill={color[3][2]}
            stroke={color[3][2]}
          />
          <ellipse cx="130" cy="192" rx="6" ry="8" fill="#FF8C36" />
          <path
            d="M130.5 224C143.479 224 154 213.255 154 200H107C107 213.255 117.521 224 130.5 224Z"
            fill="white"
          />
          <path
            d="M46.6086 139.762L62.5266 160.714C65.0657 164.056 69.4214 165.457 73.4326 164.222L90.4189 158.991L68.8638 89L46.0669 123.588C42.7978 128.549 43.0149 135.032 46.6086 139.762Z"
            fill={color[3][2]}
            stroke={color[3][2]}
          />
          <g filter="url(#filter3_d_600_2596)">
            <path
              d="M53.9702 88.4896L73.5297 142.511C77.113 152.408 86.511 159 97.0364 159H132V2L61.4723 60.773C53.3862 67.5114 50.3869 78.5926 53.9702 88.4896Z"
              fill={color[3][0]}
            />
            <path
              d="M53.9702 88.4896L73.5297 142.511C77.113 152.408 86.511 159 97.0364 159H132V2L61.4723 60.773C53.3862 67.5114 50.3869 78.5926 53.9702 88.4896Z"
              stroke={color[3][0]}
            />
          </g>
          <g filter="url(#filter4_d_600_2596)">
            <path
              d="M210.029 88.4896L190.47 142.511C186.887 152.408 177.489 159 166.963 159H132V2L202.527 60.773C210.613 67.5114 213.613 78.5926 210.029 88.4896Z"
              fill={color[3][0]}
            />
            <path
              d="M210.029 88.4896L190.47 142.511C186.887 152.408 177.489 159 166.963 159H132V2L202.527 60.773C210.613 67.5114 213.613 78.5926 210.029 88.4896Z"
              stroke={color[3][0]}
            />
          </g>
          <path
            d="M73.5454 89.6222L88.1551 129.66C90.859 137.07 97.9058 142 105.794 142H132.088V25L79.219 68.7158C73.0931 73.7811 70.8206 82.155 73.5454 89.6222Z"
            fill={color[3][1]}
            stroke={color[3][1]}
            strokeWidth="0.751045"
          />
          <path
            d="M190.63 89.6222L176.02 129.66C173.317 137.07 166.27 142 158.382 142H132.088V25L184.957 68.7158C191.082 73.7811 193.355 82.155 190.63 89.6222Z"
            fill={color[3][1]}
            stroke={color[3][1]}
            strokeWidth="0.751045"
          />
          <g filter="url(#filter5_d_600_2596)">
            <path
              d="M125.314 61.0324C130.508 55.6919 133.57 55.62 139.314 61.0324V122.032C134.115 125.851 131.064 126.051 125.314 122.032V61.0324Z"
              fill={color[0][0]}
            />
            <path
              d="M110.314 90.699C104.925 85.231 104.21 82.0538 110.314 76.0324H154.314C159.486 81.7697 159.294 84.9519 154.314 90.699H110.314Z"
              fill={color[0][0]}
            />
          </g>
          <ellipse cx="112" cy="179" rx="6" ry="8" fill="#CE996E" />
          <ellipse cx="148" cy="179" rx="6" ry="8" fill="#CE996E" />
        </g>
        <defs>
          <filter
            id="filter0_d_600_2596"
            x="12.4875"
            y="169.704"
            width="76.2379"
            height="81.452"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_600_2596"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_600_2596"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_600_2596"
            x="43.2563"
            y="0.93248"
            width="185.906"
            height="250.068"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="5" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_600_2596"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_600_2596"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_600_2596"
            x="53"
            y="134"
            width="158"
            height="117"
            filterUnits="userSpaceOnUse"
            colorInterpolation-Filters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_600_2596"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_600_2596"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_600_2596"
            x="47.9756"
            y="0.93248"
            width="88.5243"
            height="166.568"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_600_2596"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_600_2596"
              result="shape"
            />
          </filter>
          <filter
            id="filter4_d_600_2596"
            x="127.5"
            y="0.93248"
            width="88.5243"
            height="166.568"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_600_2596"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_600_2596"
              result="shape"
            />
          </filter>
          <filter
            id="filter5_d_600_2596"
            x="102"
            y="57"
            width="60.1216"
            height="75.9717"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_600_2596"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_600_2596"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  } else if (chessType === ChessClass.Knight) {
    render = (
      <svg
        width="267"
        height="155"
        viewBox="0 0 267 251"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="36.2515"
          height="9.32052"
          transform="matrix(0.879343 -0.476189 -0.476189 -0.879343 25.8121 130.15)"
          fill="#C4C4C4"
        />
        <ellipse
          rx="8.0559"
          ry="10.5915"
          transform="matrix(0.879343 -0.476189 -0.476189 -0.879343 25.4097 91.3424)"
          fill="#C4C4C4"
        />
        <rect
          width="9.39855"
          height="27.9615"
          transform="matrix(0.879343 -0.476189 -0.476189 -0.879343 35.1978 119.286)"
          fill="#C4C4C4"
        />
        <path
          d="M70.2063 222.702L81.2924 194.537L41.7509 121.518L25.8122 130.15L70.2063 222.702Z"
          fill={color[3][0]}
        />
        <path
          d="M110.939 200.645L81.2924 194.537L41.7509 121.518L57.6897 112.887L110.939 200.645Z"
          fill={color[3][1]}
        />
        <path
          d="M70.2063 222.702L105.501 239.242L81.2924 194.537L70.2063 222.702Z"
          fill={color[3][2]}
        />
        <path
          d="M110.939 200.645L105.501 239.242L81.2924 194.537L110.939 200.645Z"
          fill={color[3][0]}
        />
        <g filter="url(#filter0_d_524_2881)">
          <g filter="url(#filter1_d_524_2881)">
            <path
              d="M117.203 19.5636L117.055 19.8091C113.748 25.2687 112 31.5297 112 37.9126V62.7805C112 102.495 144.195 134.69 183.909 134.69C185.995 134.69 188.046 135.228 189.862 136.252L199.226 141.533C204.669 144.602 210.611 146.686 216.778 147.688L225.81 149.156C229.264 149.718 232.759 150 236.259 150C242.06 150 247.836 149.225 253.432 147.695L259 146.172L245.385 137.701C242.172 135.702 239.237 133.29 236.652 130.527C232.918 126.534 229.975 121.868 227.981 116.778L224 106.621L219.545 93.2241C222.442 95.2984 225.916 96.4138 229.479 96.4138H231H240.545C234.239 93.0422 228.612 88.4818 224 83.0172C219.766 77.0754 216.078 70.7631 212.981 64.1573L211.5 61L204.909 49.8448C210.384 49.8448 215.819 48.9117 220.981 47.0855L224 46.0172L214.087 42.195C209.676 40.4945 205.447 38.3573 201.462 35.8152L201.06 35.5584C196.012 32.3385 191.417 28.4596 187.395 24.0246L187.209 23.8197C182.911 19.0805 177.942 14.9969 172.459 11.6993L168.435 9.279C163.944 6.57768 158.978 4.75982 153.804 3.92329L151.757 3.59233C145.327 2.55264 138.733 3.3795 132.759 5.9748C126.292 8.7842 120.856 13.5324 117.203 19.5636Z"
              fill={color[0][0]}
            />
            <ellipse cx="133" cy="25.5" rx="25" ry="25.5" fill={color[0][0]} />
          </g>
          <g filter="url(#filter2_d_524_2881)">
            <path
              d="M112 40.0565C123.114 35.9004 128.992 36.0628 139 40.0565V54H112V40.0565Z"
              fill={color[2][0]}
            />
          </g>
          <g filter="url(#filter3_d_524_2881)">
            <ellipse cx="126" cy="114.5" rx="69" ry="68.5" fill={color[2][0]} />
          </g>
          <g filter="url(#filter4_d_524_2881)">
            <path
              d="M124.296 148.757L57 132L61.2593 161.324L124.296 194L191.593 161.324L195 132L124.296 148.757Z"
              fill={color[1][0]}
            />
          </g>
          <g filter="url(#filter5_d_524_2881)">
            <path
              d="M126.42 87L50 122.368L56.7182 132.474L126.42 151L195.282 132.474L202 122.368L126.42 87Z"
              fill={color[1][0]}
            />
          </g>
          <g filter="url(#filter6_d_524_2881)">
            <path
              d="M135 101L146 106.789V135.737L135 141V101Z"
              fill="#203661"
            />
          </g>
          <g filter="url(#filter7_d_524_2881)">
            <path
              d="M152 110L160 113.763V132.579L152 136V110Z"
              fill="#203661"
            />
          </g>
          <g filter="url(#filter8_d_524_2881)">
            <path
              d="M165 115L170 117.316V128.895L165 131V115Z"
              fill="#203661"
            />
          </g>
          <g filter="url(#filter9_d_524_2881)">
            <path
              d="M175 118L180 119.447V126.684L175 128V118Z"
              fill="#203661"
            />
          </g>
          <g filter="url(#filter10_d_524_2881)">
            <path d="M146 149.5L135 153V184L146 178V149.5Z" fill="#203661" />
          </g>
          <g filter="url(#filter11_d_524_2881)">
            <path d="M161 146L152 148.841V174L161 169.13V146Z" fill="#203661" />
          </g>
          <g filter="url(#filter12_d_524_2881)">
            <path
              d="M170 143L165 145.435V167L170 162.826V143Z"
              fill="#203661"
            />
          </g>
          <g filter="url(#filter13_d_524_2881)">
            <path
              d="M180 140L175 142.029V160L180 156.522V140Z"
              fill="#203661"
            />
          </g>
          <g filter="url(#filter14_d_524_2881)">
            <path
              d="M127.336 191.826L65 160L75.6861 186.522L81.9197 193.594L127.336 221L171.861 193.594L178.985 186.522L187 162.652L127.336 191.826Z"
              fill={color[2][0]}
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_524_2881"
            x="50"
            y="0"
            width="217"
            height="229"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_524_2881"
            x="108"
            y="0"
            width="159"
            height="158"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_524_2881"
            x="112"
            y="37"
            width="35"
            height="25"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_524_2881"
            x="57"
            y="46"
            width="146"
            height="145"
            filterUnits="userSpaceOnUse"
            colorInterpolation-filters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter4_d_524_2881"
            x="57"
            y="132"
            width="146"
            height="70"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter5_d_524_2881"
            x="50"
            y="87"
            width="160"
            height="72"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter6_d_524_2881"
            x="135"
            y="101"
            width="19"
            height="48"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter7_d_524_2881"
            x="152"
            y="110"
            width="16"
            height="34"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter8_d_524_2881"
            x="165"
            y="115"
            width="13"
            height="24"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter9_d_524_2881"
            x="175"
            y="118"
            width="13"
            height="18"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter10_d_524_2881"
            x="135"
            y="149.5"
            width="19"
            height="42.5"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter11_d_524_2881"
            x="152"
            y="146"
            width="17"
            height="36"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter12_d_524_2881"
            x="165"
            y="143"
            width="13"
            height="32"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter13_d_524_2881"
            x="175"
            y="140"
            width="13"
            height="28"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
          <filter
            id="filter14_d_524_2881"
            x="65"
            y="160"
            width="130"
            height="69"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4" dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_524_2881"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_524_2881"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  }
  return render;
};

export default ChessImage;
