import ERC20Token from './ERC20Token';
import web3 from './web3';
import { callTokenApi, getWalletAddress } from './api';

export async function fetchTokens() {
  // Get account
  const account = (await web3.eth.getAccounts())[0];

  const tokens: any[] = (await callTokenApi({
    action: 'getBalance',
    args: {
      tokenType: 'ERC20',
      forApplicationId: 1,
    },
  })) as any[];

  // console.log(tokens)

  if (account && tokens?.length) {
    tokens.forEach(async (token) => {
      token.wallet = await new ERC20Token(
        account,
        token.tokenAddress,
      ).balanceOf(account);
    });
  }

  console.log('tokens:', tokens);

  return tokens;
}
