import mqtt from 'mqtt';

const MQTT_SERVER = process.env.REACT_APP_MQTT_BROKER_ENDPOINT;
const MQTT_PORT = process.env.REACT_APP_MQTT_BROKER_PORT;
//if your server don't have username and password let blank.
const MQTT_USER = process.env.REACT_APP_MQTT_BROKER_USERNAME;
const MQTT_PASSWORD = process.env.REACT_APP_MQTT_BROKER_PASSWORD;

console.log(MQTT_SERVER);

export const mqttClient = mqtt.connect(
  process.env.REACT_APP_MQTT_BROKER_ENDPOINT,
  {
    port: parseInt(MQTT_PORT as string, 10),
    username: MQTT_USER,
    password: MQTT_PASSWORD,
  },
);
