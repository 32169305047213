import {
  Box,
  Button,
  Flex,
  Image,
  SimpleGrid,
  Tag,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { ChessSlot, ChessType } from '../../types/Chess';
import ChessImage from '../ChessImage';
import ChessInfoDialog from '../ChessInfoDialog';

type Props = {
  chess: ChessType;
  slot: ChessSlot;
};

const ChessPreview = ({ chess, slot }: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <Box
      backgroundColor="white"
      rounded="2xl"
      width="200px"
      onClick={() => setOpen(true)}
    >
      {!chess ? (
        <Flex
          alignItems="center"
          margin="20px"
          minHeight="300px"
          justifyContent="center"
        >
          <Text
            fontWeight="bold"
            fontSize="40px"
            color="#2036616e"
            textAlign="center"
          >
            Unslotted {`${slot}`}
          </Text>
        </Flex>
      ) : (
        <Flex
          align="center"
          alignItems="center"
          flexDirection="column"
          margin="20px"
        >
          {/* <Image src={chess.img} maxHeight="160px" marginTop="20px" /> */}
          <ChessImage chessId={chess.id} chessType={chess.chessType} />
          <SimpleGrid columns={1} row={4} spacingX="60px" marginTop="20px">
            <Text fontWeight="medium" fontSize="18px" color="#203661">
              HP : {chess.maxHp}
            </Text>
            <Text fontWeight="medium" fontSize="18px" color="#203661">
              ATK : {chess.atk}
            </Text>
            <Text fontWeight="medium" fontSize="18px" color="#203661">
              SPD : {chess.spd}
            </Text>
            <Text fontWeight="medium" fontSize="18px" color="#203661">
              DEF : {chess.def}
            </Text>
          </SimpleGrid>
        </Flex>
      )}
    </Box>
  );
};

export default ChessPreview;
