import React, { useEffect } from 'react';
import {
  Flex,
  Grid,
  GridItem,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import GameModel, {
  findEnemyUnitAndMove,
  findUnitAndUpdate,
  GameState,
  getPositionFromCode,
  handleEnemyPlacing,
} from 'stores/Game';
import { chesses, chesses2 } from 'common/placeholders/Chesses';
import { mqttClient } from 'common/utils/MqttService';
import { parseChesses, parseChessesResponse } from 'common/utils/Chess';
import { ChessResponse } from 'modules/types/Chess';
import GameStateDialog from 'modules/components/GameStateDialog';
import MatchResultDialog from 'modules/components/MatchResultDialog/indes';
import { getMap } from 'client';
import Board from '../../components/Board';
import { useStores } from '../../../stores';
import { tilePropertyMatrix, unitStartLocationMatrix } from './prototypeMap';
import InfoBar from '../../components/InfoBar';

const WIDTH = 6;
const HEIGHT = 8;

const Game: React.FC<any> = observer((): JSX.Element => {
  const { GameStore, UserStore } = useStores();

  useEffect(() => {
    getMap(GameStore.gameId, WIDTH, HEIGHT).then((map: any) => {
      console.log('map', map);
      GameStore.createPrototypeBoard(
        WIDTH,
        HEIGHT,
        map,
        unitStartLocationMatrix,
      );
    });

    mqttClient.subscribe(`/dechess/sub/connect/${UserStore.clientId}`);
    mqttClient.subscribe(`/dechess/sub/join/${UserStore.clientId}`);
    mqttClient.subscribe(`/dechess/sub/start-game/${UserStore.clientId}`);
    mqttClient.subscribe(`/dechess/sub/spawn-item/${GameStore.getGameId()}`);
    // mqttClient.subscribe(`/dechess/sub/room-ready/${UserStore.clientId}`);
    mqttClient.subscribe(`/dechess/sub/position/${UserStore.clientId}/#`);
    mqttClient.subscribe(`/dechess/sub/position/${GameStore.opponent}/#`);
    mqttClient.subscribe(
      `/dechess/sub/timestamp/${UserStore.clientId}/${GameStore.getGameId()}`,
    );
    mqttClient.subscribe(`/dechess/sub/surrender/${GameStore.opponent}/#`);
    mqttClient.subscribe(`/dechess/sub/surrender/${UserStore.clientId}/#`);
    mqttClient.on('message', (topic, message) => {
      const data = JSON.parse(message.toString());
      console.log(topic, data);
      if (
        topic.includes('start') &&
        topic.includes(UserStore.clientId) &&
        GameStore.gameState === GameState.ROOM_READY
      ) {
        GameStore.setGameState(GameState.PLACING);
      }

      if (
        topic.includes('position') &&
        topic.includes(UserStore.clientId) &&
        topic.includes('place') &&
        (GameStore.gameState === GameState.PLACING ||
          GameStore.gameState === GameState.ENEMY_PLACING)
      ) {
        const parsedChesses: ChessResponse[] = parseChessesResponse(
          data.position,
        );
        console.log('after parsed:', parsedChesses);

        GameStore.setOpponentChessResponse(parsedChesses);
        if (GameStore.gameState === GameState.ENEMY_PLACING) {
          handleEnemyPlacing();
        } else {
          GameStore.setGameState(GameState.ENEMY_FINISHED_PLACING);
        }
      }

      if (
        topic.includes('position') &&
        topic.includes(UserStore.clientId) &&
        topic.includes('move') &&
        GameStore.gameState === GameState.ENEMY_TURN
      ) {
        console.log('the problem is here', data);
        data.position.map((chess: ChessResponse) => {
          findEnemyUnitAndMove(parseInt(chess.chess_id, 10), chess.position);
          return chess;
        });
        if (GameStore.currentTurn >= 3)
          GameStore.setGameState(GameState.MOVE_OR_ATTACK);
        else GameStore.setGameState(GameState.MOVE_ONLY);
      }

      if (
        topic.includes('position') &&
        topic.includes(GameStore.opponent) &&
        topic.includes('attack') &&
        GameStore.gameState === GameState.ENEMY_TURN
      ) {
        console.log('attacked');
        data.enemy_position.map((chess: ChessResponse) => {
          findUnitAndUpdate(
            parseInt(chess.chess_id, 10),
            chess.hp,
            data.lastAction,
          );
          return chess;
        });
        if (GameStore.currentTurn >= 3)
          GameStore.setGameState(GameState.MOVE_OR_ATTACK);
        else GameStore.setGameState(GameState.MOVE_ONLY);
      }
      if (topic.includes('timestamp')) {
        console.log('on timestamp topic:', message.toString());
        GameStore.setCurrentTimestamp(
          data.timestamp,
          data.turn.toLowerCase() === UserStore.clientId.toLowerCase(),
        );
      }
      if (topic.includes('surrender')) {
        const { loser } = data;
        if (loser === UserStore.clientId) {
          GameStore.setGameState(GameState.LOSE);
        } else {
          GameStore.setGameState(GameState.WIN);
        }
      }
      if (topic.includes('spawn-item')) {
        console.log('item spawn:', data);
        const { position, type } = data;
        switch (type) {
          case 'HEALTH_POTION': {
            console.log('health potion position:', position);
            const { row, col } = getPositionFromCode(position);
            console.log('set item', row, col, GameStore.boardData);
            GameStore.setItem(row, col);
            break;
          }
          default:
            console.log('Invalid item');
        }
      }
    });
    const myChesses = GameStore.getMyChesses();
    const units = myChesses?.units;

    if (units) {
      GameStore.setShowingUnit({
        unit: { ...units[0] },
      });
    }

    return () => {
      mqttClient.unsubscribe('#');
      mqttClient.removeAllListeners('message');
    };
  }, []);

  useEffect(() => {
    console.info('publishing join');
    mqttClient.publish(
      `/dechess/pub/join/${UserStore.clientId}`,
      `{"room_id": "${GameStore.gameId}", "public_address": "${UserStore.clientId}", "token": "${UserStore.jwt}"}`,
    );
  }, []);

  return (
    <Flex
      align="center"
      alignItems="center"
      minHeight="100%"
      minWidth="100%"
      userSelect="none"
      background="#777DED"
    >
      <Flex
        minHeight="100%"
        minWidth="100%"
        justify="center"
        justifyContent="center"
        flexDirection="row"
      >
        <Board boardData={GameStore.getBoardData()} />
        <InfoBar />
      </Flex>
      <GameStateDialog
        open={GameStore.gameState === GameState.ROOM_READY}
        guidingText="Connecting..."
      />
      <MatchResultDialog
        open={
          GameStore.gameState === GameState.WIN ||
          GameStore.gameState === GameState.LOSE
        }
        win={GameStore.gameState === GameState.WIN}
      />
    </Flex>
  );
});

export default Game;
