import { createContext, useContext } from 'react';
import GameStore from './Game';
import UserStore from './User';
import AppStore from './App'

export const stores = {
  GameStore,
  UserStore,
  AppStore
};

export const storesContext = createContext(stores);
export const useStores = () => useContext(storesContext);
