import { useDisclosure } from '@chakra-ui/hooks';
import { Image } from '@chakra-ui/image';
import { Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import { Button, Flex, Progress, Spacer } from '@chakra-ui/react';
import { mqttClient } from 'common/utils/MqttService';
import { ChessSlot, ChessType } from 'modules/types/Chess';
import { useEffect, useState } from 'react';
import { useStores } from 'stores';
import ChessCard from '../ChessCard';
import ChessPreview from '../ChessPreview';

function AcceptMatchDialog({
  open = false,
  onSetClose,
  onAccept,
  accepted = false,
}: {
  open?: boolean;
  onSetClose?: () => void;
  onAccept: () => void;
  accepted: boolean;
}) {
  const { GameStore } = useStores();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [remainingTime, setRemainingTime] = useState(10);
  const [intervalId, setIntervalId] = useState<any>();
  const [isCalledOnce, setIsCalledOnce] = useState(false);
  useEffect(() => {
    if (open) {
      onOpen();
      setRemainingTime(10);
      setIsCalledOnce(false);
      const iId = setInterval(() => {
        setRemainingTime((rTime) => rTime - 0.01);
      }, 10);
      setIntervalId(iId);
    } else {
      clearInterval(intervalId);
      onClose();
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [open]);

  const onCancelMatchFinding = () => {
    console.log('exit match finding');
    onSetClose?.();
  };
  useEffect(() => {
    if (remainingTime <= 0 && !isCalledOnce) {
      onCancelMatchFinding();
      setIsCalledOnce(true);
    }
  }, [remainingTime]);

  const renderTeam = (): Array<JSX.Element> => {
    return [
      ChessSlot.KING,
      ChessSlot.ROOK,
      ChessSlot.KNIGHT,
      ChessSlot.BISHOP_1,
      ChessSlot.BISHOP_2,
    ].map((chessSlot) => {
      const chess = GameStore.myChesses?.units.find(
        (c) => c.slot === chessSlot,
      ) as ChessType;
      return <ChessPreview chess={chess} slot={chessSlot} />;
    });
  };

  return (
    <>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        closeOnOverlayClick={false}
        motionPreset="scale"
        size="5xl"
        onEsc={onCancelMatchFinding}
      >
        <ModalOverlay />
        <ModalContent overflow="hidden" borderRadius="30px">
          <ModalHeader
            backgroundColor="purple.100"
            borderRadius="30px 30px 0 0"
          >
            <Text
              fontSize="32px"
              fontWeight="medium"
              textAlign="center"
              color="#203661"
            >
              Match found!
            </Text>
          </ModalHeader>
          <Progress
            value={remainingTime * 10}
            size="xs"
            colorScheme="pink"
            isAnimated
          />
          <ModalBody
            minHeight="500px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            p="20px"
          >
            <Text
              fontSize="24px"
              textAlign="center"
              my="10px"
              px="8px"
              borderRadius="6px"
              fontWeight="medium"
              color="#203661"
            >
              {GameStore.opponent} ELO: {GameStore.opponentELO}
            </Text>
            <Flex>{renderTeam()}</Flex>
            <Spacer />
            <Flex width="100%" justifyContent="flex-end">
              <Button
                variant="outline"
                mr="10px"
                size="lg"
                onClick={() => onCancelMatchFinding()}
                disabled={accepted}
              >
                Decline ({Math.ceil(remainingTime)}s)
              </Button>
              <Button size="lg" onClick={() => onAccept()} disabled={accepted}>
                {accepted ? 'Waiting for opponent to accept' : 'Accept'}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AcceptMatchDialog;
