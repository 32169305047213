import {
  types,
  cast,
  Instance,
  getSnapshot,
  applySnapshot,
} from 'mobx-state-tree';
import localForage from 'localforage';
import { persist } from 'mst-persist';

import { cloneDeep } from 'lodash';

import UserModel from 'stores/User';
import { postPlaceChessPieces, postSurrender, postTurnHandler } from 'client';
import { ChessResponse, ChessSlot } from 'modules/types/Chess';
import { ChessAction } from 'common/utils/Chess';
import { ChessClass, ChessType } from '../../modules/types/Chess';
import { chesses } from '../../common/placeholders/Chesses';

export enum TileActiveOperation {
  BLOCKED,
  PASS,
  ALLOWED,
  ALLOWED_NOPASS,
}

export enum GameState {
  CONNECTING = 'CONNECTING',
  ROOM_READY = 'ROOM_READY',
  ACCEPTANCE = 'ACCEPTANCE',
  PLACING = 'PLACING',
  ENEMY_PLACING = 'ENEMY_PLACING',
  ENEMY_FINISHED_PLACING = 'ENEMY_FINISHED_PLACING',
  MOVE_ONLY = 'MOVE_ONLY',
  ATTACK_ONLY = 'ATTACK_ONLY',
  MOVE_OR_ATTACK = 'MOVE_OR_ATTACK',
  ENEMY_TURN = 'ENEMY_TURN',
  WIN = 'WIN',
  LOSE = 'LOSE',
}

export interface ITileData {
  tileProperty: string;
  unit?: ChessType;
  isHighlighted: boolean;
  isEnemy: boolean;
  isItem?: boolean;
}

export interface IActiveUnitData {
  unit: ChessType;
  row: number;
  col: number;
}

export interface IShowingInfoUnitData {
  unit: ChessType;
}

export interface ActiveTileOption {
  [key: string]: TileActiveOperation;

  unit: TileActiveOperation;
  enemy: TileActiveOperation;

  grass: TileActiveOperation;
  tree: TileActiveOperation;
  water: TileActiveOperation;
}

const defaultActiveTileOption: ActiveTileOption = {
  unit: TileActiveOperation.BLOCKED,
  enemy: TileActiveOperation.ALLOWED_NOPASS,

  grass: TileActiveOperation.ALLOWED,
  tree: TileActiveOperation.BLOCKED,
  water: TileActiveOperation.BLOCKED,
};

const ChessData = types.model('ChessData', {
  name: types.string,
  id: types.number,
  img: types.string,
  chessType: types.enumeration<ChessClass>(
    'ChessClass',
    Object.values(ChessClass),
  ),
  maxHp: types.number,
  curHp: types.number,
  atk: types.number,
  def: types.number,
  spd: types.number,
  skill: types.string,
  slot: types.enumeration<ChessSlot>('ChessSlot', Object.values(ChessSlot)),
  price: types.number,
  saleListed: types.boolean,
  nftId: types.string,
  playerId: types.number,
});

const ChessResponseModel = types.model('ChessResponse', {
  chess_id: types.string,
  type: types.enumeration<ChessClass>('ChessClass', Object.values(ChessClass)),
  max_hp: types.number,
  hp: types.number,
  atk: types.number,
  def: types.number,
  spd: types.number,
  position: types.string,
  timestamp: types.string,
  slot: types.enumeration<ChessSlot>('ChessSlot', Object.values(ChessSlot)),
  price: types.number,
  saleListed: types.boolean,
  nftId: types.string,
  playerId: types.number,
});

const TileData = types.model('TileData', {
  tileProperty: types.string,
  unit: types.maybe(ChessData),
  isHighlighted: types.boolean,
  isEnemy: types.boolean,
  isItem: types.optional(types.boolean, false),
});

const ActiveUnitData = types.model('ActiveUnitData', {
  unit: ChessData,
  row: types.number,
  col: types.number,
});

const HistoryData = types.model('HistoryData', {
  message: types.string,
  src: types.string,
  dest: types.optional(types.string, ''),
});

const ShowingInfoUnitData = types.model('ShowingInfoUnitData', {
  unit: types.maybe(ChessData),
});

const GameStateData = types.enumeration<GameState>(
  'GameStateData',
  Object.values(GameState),
);

const MyChesses = types.model('MyChesses', {
  units: types.array(ChessData),
  placing: types.number,
});

interface ITileDataMST extends Instance<typeof TileData> {}

const GameModel = types
  .model('BoardModel', {
    gameId: types.string,
    width: types.optional(types.integer, 0),
    height: types.optional(types.integer, 0),
    boardData: types.array(types.array(TileData)),
    activeUnit: types.maybe(ActiveUnitData),
    showingInfoUnit: types.maybe(ShowingInfoUnitData),
    gameState: types.maybe(GameStateData),
    myChesses: types.maybe(MyChesses),
    myAllChesses: types.array(ChessData),
    isLoadingMyChesses: types.boolean,
    isLoadingMyAllChesses: types.boolean,
    opponent: types.string,
    opponentELO: types.optional(types.integer, 0),
    opponentChessesResponse: types.array(types.maybe(ChessResponseModel)),
    isPlayer2: types.boolean,
    currentTurn: types.optional(types.integer, 0),
    history: types.array(HistoryData),
    highlightSrc: types.string,
    highlightDest: types.string,
    highlightHistSrc: types.string,
    highlightHistDest: types.string,
    currentTimestamp: types.optional(types.number, 0),
    timestampTurn: types.optional(types.boolean, true), // True = my turn
    remainingTime: types.integer,
    myConsequenceSkip: types.integer,
  })
  .actions((self) => {
    // reseter
    let initialState = {};
    const afterCreate = () => {
      initialState = getSnapshot(self);
    };
    const reset = () => {
      applySnapshot(self, initialState);
    };

    // GETTER
    const getWidth = (): number => {
      return self.width;
    };
    const getHeight = (): number => {
      return self.height;
    };
    const getBoardData = (): Array<Array<ITileData>> => {
      return self.boardData;
    };
    const getActiveUnit = (): IActiveUnitData | undefined => {
      return self.activeUnit;
    };
    const getTile = (row: number, col: number): ITileData | undefined => {
      if (
        row < 0 ||
        row >= self.boardData.length ||
        col < 0 ||
        col >= self.boardData[0].length
      )
        return undefined;
      return self.boardData[row][col];
    };
    const getShowingUnit = () => {
      return self.showingInfoUnit;
    };
    const getMyChesses = () => {
      return self.myChesses;
    };
    const getGameId = () => {
      return self.gameId;
    };
    const getCurrentTimestamp = () => {
      return self.currentTimestamp;
    };

    const getTimeStampTurn = () => {
      return self.timestampTurn;
    };

    const getCurrentTurn = () => {
      return (
        Math.floor((Date.now() - self.currentTimestamp) / 1000 / 20) % 2 ===
        (getTimeStampTurn() ? 0 : 1)
      );
    };

    // SETTER
    const setWidth = (width: number) => {
      self.width = width;
    };
    const setHeight = (height: number) => {
      self.height = height;
    };
    const setBoardData = (boardData: Array<Array<ITileData>>) => {
      self.boardData = cast(boardData);
    };
    const setUnitInTile = (
      row: number,
      col: number,
      unit?: ChessType,
      isEnemy?: boolean,
    ) => {
      if (
        row < 0 ||
        row >= self.boardData.length ||
        col < 0 ||
        col >= self.boardData[0].length
      )
        return;

      self.boardData[row][col].unit = unit;

      self.boardData[row][col].isEnemy = !!isEnemy;
    };
    const setShowingUnit = (unit: IShowingInfoUnitData | undefined) => {
      self.showingInfoUnit = unit;
    };
    const setGameState = (newState?: GameState, setTimestamp = true) => {
      self.gameState = newState;
      if (setTimestamp) {
        self.currentTimestamp = Date.now();
      }
    };
    const setMyChesses = (newChesses: Array<ChessType>, placing = 0) => {
      self.myChesses = { units: cast(newChesses), placing };
    };
    const setMyAllChesses = (newChesses: Array<ChessType>) => {
      self.myAllChesses = cast(newChesses);
    };
    const setLoadingMyChesses = (status: boolean) => {
      self.isLoadingMyChesses = status;
    };
    const setLoadingMyAllChesses = (status: boolean) => {
      self.isLoadingMyAllChesses = status;
    };
    const setGameId = (gameId: string) => {
      self.gameId = gameId;
    };
    const setOpponent = (opponent: string) => {
      self.opponent = opponent;
    };
    const setOpponentELO = (elo: number) => {
      self.opponentELO = elo;
    };
    const setIsPlayer2 = (isPlayer2 = false) => {
      self.isPlayer2 = isPlayer2;
    };
    const setOpponentChessResponse = (chessResponses: ChessResponse[]) => {
      console.log('this is fucking chess response', chessResponses);
      self.opponentChessesResponse = cast(chessResponses);
    };
    const setCurrentTurn = (turn: number) => {
      self.currentTurn = turn;
    };
    const setHighLightHist = (src: string, dest: string) => {
      self.highlightHistSrc = src;
      self.highlightHistDest = dest;
    };

    const setHighLight = (src: string, dest: string) => {
      self.highlightSrc = src;
      self.highlightDest = dest;
    };

    const setCurrentTimestamp = (
      currentTimestamp: number,
      timestampTurn: boolean,
    ) => {
      self.currentTimestamp = currentTimestamp;
      self.timestampTurn = timestampTurn;
    };

    const setRemainingTime = () => {
      self.remainingTime = parseInt(
        `${21 - ((Date.now() - self.currentTimestamp) % 20000) / 1000}`,
        10,
      );
    };

    const setConsequenceSkip = (cSkip: number) => {
      self.myConsequenceSkip = cSkip;
    };

    const addGameHistory = (
      src: string,
      dest: string,
      srcType: ChessClass,
      isAttack = false,
      isEnemy = false,
      destType?: ChessClass,
    ) => {
      const subject = isEnemy ? 'Enemy' : 'You';
      const logMsg = isAttack
        ? `${subject} attack ${destType} at ${dest} with ${srcType}`
        : `${subject} move ${srcType} from ${src} to ${dest}`;
      console.log('srcType in addGameHistory:', srcType);

      self.history = cast([{ message: logMsg, src, dest }, ...self.history]);
    };

    const addSkipTurnHistory = (isEnemy: boolean) => {
      const subject = isEnemy ? 'Enemy' : 'You';
      const logMsg = `${subject} skipped the turn!`;
      // console.log('srcType in addGameHistory:', srcType);
      self.history = cast([
        { message: logMsg, src: '', dest: '' },
        ...self.history,
      ]);
    };

    const setChessName = (id: number, name: string) => {
      for (let i = 0; i < self.myAllChesses.length; i++) {
        if (self.myAllChesses[i].id === id) self.myAllChesses[i].name = name;
      }

      for (let i = 0; i < (self.myChesses?.units.length || 0); i++) {
        if (self.myChesses?.units[i].id === id)
          self.myChesses.units[i].name = name;
      }
    };

    const setItem = (row: number, col: number, itemStats: boolean = true) => {
      self.boardData[row][col].isItem = itemStats;
    };

    return {
      afterCreate,
      reset,

      getWidth,
      getHeight,
      getBoardData,
      getActiveUnit,
      getTile,
      getShowingUnit,
      getMyChesses,
      getGameId,
      getCurrentTimestamp,
      getTimeStampTurn,
      getCurrentTurn,

      setWidth,
      setHeight,
      setBoardData,
      setUnitInTile,
      setShowingUnit,
      setGameState,
      setMyChesses,
      setMyAllChesses,
      setLoadingMyChesses,
      setLoadingMyAllChesses,
      setGameId,
      setOpponent,
      setOpponentELO,
      setIsPlayer2,
      setOpponentChessResponse,
      setCurrentTurn,
      setHighLight,
      setHighLightHist,
      setCurrentTimestamp,
      setRemainingTime,
      setConsequenceSkip,
      setChessName,
      setItem,

      addGameHistory,
      addSkipTurnHistory,
    };
  })
  .actions((self) => {
    const createPrototypeBoard = (
      width: number,
      height: number,
      tilePropertyArray: Array<Array<number>>,
      unitStartLocationArray: Array<Array<number>>,
    ) => {
      self.setWidth(width);
      self.setHeight(height);
      const boardData: Array<Array<ITileData>> = tilePropertyArray.map(
        (rows: number[], row: number) => {
          return rows.map((tileId: number, col: number) => {
            let tileProperty: string = 'grass';
            switch (tileId) {
              case 1:
                tileProperty = 'water';
                break;
              case 2:
                tileProperty = 'tree';
                break;
              default:
                tileProperty = 'grass';
                break;
            }
            const unit: ChessType | undefined =
              unitStartLocationArray[row][col] === -1
                ? undefined
                : chesses[unitStartLocationArray[row][col]];

            return {
              tileProperty,
              unit,
              isHighlighted: false,
              isEnemy: !!unit && unit.chessType === ChessClass.King,
            };
          });
        },
      );
      self.setBoardData(boardData);
    };

    const _setActiveTile = (
      boardData: ITileData[][],
      row: number,
      col: number,
      options: ActiveTileOption = defaultActiveTileOption,
    ): TileActiveOperation => {
      if (
        row < 0 ||
        row >= boardData.length ||
        col < 0 ||
        col >= boardData[0].length
      )
        return TileActiveOperation.BLOCKED;

      if (!boardData[row][col].unit || boardData[row][col].isEnemy) {
        const operation = boardData[row][col].isEnemy
          ? options.enemy
          : options[boardData[row][col].tileProperty];
        if (
          operation === TileActiveOperation.ALLOWED ||
          operation === TileActiveOperation.ALLOWED_NOPASS
        ) {
          if (
            !(
              boardData[row][col].isEnemy &&
              GameModel.gameState === GameState.MOVE_ONLY
            )
          )
            boardData[row][col].isHighlighted = true;
        }
        return operation;
      }

      return options.unit;
    };

    const setActiveUnit = (activeUnit: IActiveUnitData | undefined) => {
      const boardData = self.getBoardData();

      for (let i = 0; i < boardData.length; i++) {
        for (let j = 0; j < boardData[i].length; j++) {
          boardData[i][j].isHighlighted = false;
        }
      }

      if (activeUnit !== undefined) {
        self.activeUnit = cast(cloneDeep(activeUnit));

        let highlight: number[][][] = [];

        switch (activeUnit.unit.chessType) {
          case ChessClass.Bishop:
            highlight = [
              [
                [-1, -1],
                [-2, -2],
              ],
              [
                [-1, 1],
                [-2, 2],
              ],
              [
                [1, 1],
                [2, 2],
              ],
              [
                [1, -1],
                [2, -2],
              ],
            ];
            break;

          case ChessClass.Knight:
            highlight = [
              [[-1, -2]],
              [[-2, -1]],
              [[-1, 2]],
              [[-2, 1]],
              [[1, 2]],
              [[2, 1]],
              [[1, -2]],
              [[2, -1]],
            ];
            break;

          case ChessClass.King:
            highlight = [
              [[-1, -1]],
              [[-1, 0]],
              [[-1, 1]],
              [[0, -1]],
              [[0, 1]],
              [[1, -1]],
              [[1, 0]],
              [[1, 1]],
            ];
            break;

          case ChessClass.Rook:
            highlight = [
              [
                [-1, 0],
                [-2, 0],
              ],
              [
                [1, 0],
                [2, 0],
              ],
              [
                [0, 1],
                [0, 2],
              ],
              [
                [0, -1],
                [0, -2],
              ],
            ];
            break;

          default:
            highlight = [];
            break;
        }

        for (const positions of highlight) {
          for (const position of positions) {
            const operation = _setActiveTile(
              boardData,
              activeUnit.row + position[0],
              activeUnit.col + position[1],
            );
            if (
              operation === TileActiveOperation.BLOCKED ||
              operation === TileActiveOperation.ALLOWED_NOPASS
            )
              break;
          }
        }
      } else {
        self.activeUnit = undefined;
      }

      self.setBoardData(boardData);
    };

    return {
      setActiveUnit,
      createPrototypeBoard,
    };
  })
  .create({
    gameId: '',
    width: 0,
    height: 0,
    boardData: [],
    opponent: '',
    isPlayer2: false,
    isLoadingMyChesses: false,
    isLoadingMyAllChesses: false,
    history: [],
    highlightSrc: '',
    highlightDest: '',
    highlightHistSrc: '',
    highlightHistDest: '',
    remainingTime: 0,
    myConsequenceSkip: 0,
  });

// persist('Game', GameModel, {
//   storage: localForage,
//   jsonify: false,
//   whitelist: ['width', 'height', 'boardData'],
// }).then(() => console.log('GameStore has been hydrated'));

export const getPositionCode = (row: number, col: number) => {
  const rowCode = (row + 1).toString();
  const colCode = String.fromCharCode(col + 65);
  return colCode + rowCode;
};

export const getPositionFromCode = (positionCode: string) => {
  const colCode = positionCode.substring(0, 1);
  const rowCode = positionCode.substring(1);
  const row = parseInt(rowCode, 10) - 1;
  const col = colCode.charCodeAt(0) - 65;
  return { row, col };
};

export async function moveUnitAndSubmit(
  fromRow: number,
  fromCol: number,
  toRow: number,
  toCol: number,
) {
  const tile = GameModel.getTile(fromRow, fromCol);
  const unit = cloneDeep(tile?.unit);

  console.log('MOVED', unit, toRow, toCol);

  if (GameModel.boardData[toRow][toCol].isItem) {
    GameModel.setItem(toRow, toCol, false);
    if (unit) {
      unit.curHp =
        unit?.curHp + 50 > unit?.maxHp ? unit?.maxHp : unit?.curHp + 50;
    }
  }

  GameModel.setUnitInTile(
    toRow,
    toCol,
    unit,
    GameModel.getTile(fromRow, fromCol)?.isEnemy,
  );
  const srcPositionCode = getPositionCode(fromRow, fromCol);
  const destPositionCode = getPositionCode(toRow, toCol);
  GameModel.addGameHistory(
    srcPositionCode,
    destPositionCode,
    unit?.chessType as ChessClass,
    false,
    tile?.isEnemy,
  );
  GameModel.setHighLight(srcPositionCode, destPositionCode);
  GameModel.setUnitInTile(fromRow, fromCol);
  GameModel.setHighLight(srcPositionCode, destPositionCode);
  GameModel.setActiveUnit(undefined);
}

export async function moveActiveUnitAndSubmit(toRow: number, toCol: number) {
  const activeUnit = GameModel.getActiveUnit();
  const unitId = activeUnit?.unit.id;

  if (!activeUnit) return;

  GameModel.setGameState(GameState.ENEMY_TURN);
  await moveUnitAndSubmit(activeUnit.row, activeUnit.col, toRow, toCol);
  await postTurnHandler(
    GameModel.gameId,
    UserModel.clientId,
    unitId?.toString() || '',
    getPositionCode(toRow, toCol),
    'MOVE',
    false,
  );
}

export async function handleEnemyPlacing() {
  if (GameModel.isPlayer2) GameModel.setGameState(GameState.ENEMY_TURN);
  else GameModel.setGameState(GameState.MOVE_ONLY);

  GameModel.opponentChessesResponse.map((chess) => {
    if (chess) {
      const pos = getPositionFromCode(chess.position);
      console.log(pos, chess);
      GameModel.setUnitInTile(
        pos.row,
        pos.col,
        {
          atk: chess.atk,
          chessType: chess.type,
          curHp: chess.hp,
          def: chess.def,
          maxHp: chess.max_hp,
          id: parseInt(chess.chess_id, 10),
          img: `/images/${chess.type.toLowerCase()}.png`,
          name: chess.type,
          skill: '',
          spd: chess.spd,
          slot: chess.slot,
          price: chess.price,
          saleListed: chess.saleListed,
          nftId: chess.nftId,
          playerId: chess.playerId,
        },
        true,
      );
    }
    return chess;
  });
}

export async function placeNewUnit(row: number, col: number, isEnemy = false) {
  const myChesses = GameModel.getMyChesses();
  const units = myChesses?.units;
  const placing = myChesses?.placing;

  if (units && placing !== undefined) {
    GameModel.setUnitInTile(row, col, { ...units[placing] }, isEnemy);
    GameModel.setMyChesses(units, placing + 1);
    if (placing < 4) {
      GameModel.setShowingUnit({ unit: { ...units[placing + 1] } });
    } else {
      const positions = myChesses.units.map((chess) => {
        return { [chess.id.toString()]: '' };
      });
      const boardData = GameModel.getBoardData();

      for (let i = 0; i < boardData.length; i++) {
        for (let j = 0; j < boardData[i].length; j++) {
          const chessId = boardData[i][j].unit?.id.toString() || '';
          if (boardData[i][j].unit) {
            positions.forEach((chessPosition) => {
              if (Object.keys(chessPosition).includes(chessId)) {
                chessPosition[chessId] = getPositionCode(i, j);
              }
              return chessPosition;
            });
          }
        }
      }
      postPlaceChessPieces(GameModel.gameId, UserModel.clientId, positions);
      if (GameModel.gameState === GameState.ENEMY_FINISHED_PLACING) {
        handleEnemyPlacing();
      } else {
        GameModel.setGameState(GameState.ENEMY_PLACING);
      }
    }
  }
}

export async function attackUnitAndSubmit(
  fromRow: number,
  fromCol: number,
  toRow: number,
  toCol: number,
) {
  const fromUnit = cloneDeep(GameModel.getTile(fromRow, fromCol)?.unit);

  const toTile = GameModel.getTile(toRow, toCol);
  const toUnit = cloneDeep(toTile?.unit);

  if (!fromUnit || !toUnit) return;

  const totalDamage = Math.max(1, fromUnit?.atk - toUnit?.def);

  toUnit.curHp -= totalDamage;

  const attackDest = getPositionCode(toRow, toCol);
  GameModel.addGameHistory(
    '00',
    attackDest,
    fromUnit.chessType,
    true,
    false,
    toUnit?.chessType as ChessClass,
  );
  GameModel.setHighLight('', attackDest);
  // console.log("ATTACK", toUnit)

  if (toUnit.curHp <= 0) {
    GameModel.setUnitInTile(toRow, toCol);
    GameModel.setShowingUnit(undefined);
    if (toUnit.chessType === ChessClass.King)
      GameModel.setGameState(GameState.WIN);
    console.log('to attacked unit', toUnit, GameModel.gameState);
    console.log(GameModel.gameState === GameState.WIN);
  } else {
    GameModel.setUnitInTile(toRow, toCol, toUnit, toTile?.isEnemy);
  }
}

export async function activeUnitAttackAndSubmit(toRow: number, toCol: number) {
  const activeUnit = GameModel.getActiveUnit();

  if (!activeUnit) return;

  GameModel.setGameState(GameState.ENEMY_TURN);
  await postTurnHandler(
    GameModel.gameId,
    UserModel.clientId,
    activeUnit.unit.id.toString(),
    getPositionCode(toRow, toCol),
    'ATTACK',
    true,
  );
  await attackUnitAndSubmit(activeUnit.row, activeUnit.col, toRow, toCol);
}

export async function findUnitPos(chessId: number) {
  const boardData = GameModel.getBoardData();

  for (let i = 0; i < boardData.length; i++) {
    for (let j = 0; j < boardData[i].length; j++) {
      if (boardData[i][j].unit && boardData[i][j].unit?.id === chessId) {
        return { row: i, col: j };
      }
    }
  }
  return { row: -1, col: -1 };
}

export async function findEnemyUnitAndMove(chessId: number, toPos: string) {
  const { row, col } = await findUnitPos(chessId);

  const { row: toRow, col: toCol } = getPositionFromCode(toPos);

  console.log(chessId, row, toRow, col, toCol);

  if ((toRow !== row || toCol !== col) && row !== -1 && col !== -1)
    await moveUnitAndSubmit(row, col, toRow, toCol);
}

export async function findTileFromChessId(chessId: number) {
  const boardData = GameModel.getBoardData();

  for (let i = 0; i < boardData.length; i++) {
    for (let j = 0; j < boardData[i].length; j++) {
      if (boardData[i][j].unit && boardData[i][j].unit?.id === chessId) {
        return boardData[i][j];
      }
    }
  }
  return undefined;
}

export async function findUnitAndUpdate(
  chessId: number,
  updatedHp: number,
  lastAction: string,
) {
  const tileData = await findTileFromChessId(chessId);

  if (tileData && tileData.unit?.curHp !== updatedHp) {
    const { row, col } = await findUnitPos(chessId);
    const newUnitInTile = cloneDeep(tileData.unit);
    if (newUnitInTile) newUnitInTile.curHp = updatedHp;
    console.log(row, col, newUnitInTile);

    const attackSource = lastAction.slice(3, 4);
    const attackDest = getPositionCode(row, col);
    console.log('chess action:', attackSource, ChessAction[attackSource]);

    GameModel.addGameHistory(
      '00',
      attackDest,
      ChessAction[attackSource],
      true,
      true,
      newUnitInTile?.chessType as ChessClass,
    );
    GameModel.setHighLight('', attackDest);

    if (newUnitInTile && newUnitInTile.curHp <= 0) {
      GameModel.setUnitInTile(row, col);
      GameModel.setShowingUnit(undefined);
      if (newUnitInTile.chessType === ChessClass.King)
        GameModel.setGameState(GameState.LOSE);
    } else {
      console.log('update', tileData, newUnitInTile);
      GameModel.setUnitInTile(row, col, newUnitInTile, tileData.isEnemy);
    }
  }
}

setInterval(() => {
  const myTurn = GameModel.getCurrentTurn();
  const timestamp = GameModel.getCurrentTimestamp();

  if (Date.now() - timestamp >= 5000) {
    if (
      !myTurn &&
      (GameModel.gameState === GameState.MOVE_ONLY ||
        GameModel.gameState === GameState.ATTACK_ONLY ||
        GameModel.gameState === GameState.MOVE_OR_ATTACK)
    ) {
      GameModel.setGameState(GameState.ENEMY_TURN, false);
      const boardData = cloneDeep(GameModel.getBoardData());
      for (let i = 0; i < boardData.length; i++) {
        for (let j = 0; j < boardData[i].length; j++) {
          boardData[i][j].isHighlighted = false;
        }
      }
      GameModel.setBoardData(boardData);
      GameModel.addSkipTurnHistory(false);
      GameModel.setConsequenceSkip(GameModel.myConsequenceSkip + 1);
      if (GameModel.myConsequenceSkip === 3) {
        postSurrender(GameModel.gameId);
      }
    } else if (myTurn && GameModel.gameState === GameState.ENEMY_TURN) {
      if (GameModel.currentTurn >= 3)
        GameModel.setGameState(GameState.MOVE_OR_ATTACK, false);
      else GameModel.setGameState(GameState.MOVE_ONLY, false);
      GameModel.addSkipTurnHistory(true);
    }
  }
  GameModel.setRemainingTime();
}, 500);

export default GameModel;
