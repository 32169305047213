import { useBoolean, useDisclosure } from '@chakra-ui/hooks';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/modal';
import {
  Button,
  ButtonGroup,
  Collapse,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Image,
  Input,
  Tag,
  Text,
  useEditableControls,
  useToast,
} from '@chakra-ui/react';
import Confetti from 'react-confetti';
import {
  getAllChessPieces,
  getChessPieces,
  patchListedChess,
  patchOnUsedItem,
  patchUnlistedChess,
  patchUpdateName,
  postBuyP2P,
} from 'client';
import { ChessClass, ChessSlot, ChessType } from 'modules/types/Chess';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStores } from 'stores';
import { parseChesses } from 'common/utils/Chess';
import { fetchTokens } from 'common/utils/tokenApi/fetchTokens';
import web3 from 'common/utils/tokenApi/web3';
import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import ConfirmDialog from '../ConfirmDialog';

function ChessInfoDialog({
  open = false,
  isNew = false,
  onCloseDialog,
  chess,
  listedMode = false,
}: {
  open?: boolean;
  onCloseDialog?: () => void;
  chess?: ChessType;
  isNew?: boolean;
  listedMode?: boolean;
}) {
  const [openListForm, setOpenListForm] = useBoolean(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { GameStore, UserStore } = useStores();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openConfirmBuyDialog, setOpenConfirmBuyDialog] = useState(false);
  const [listedPrice, setListedPrice] = useState('');
  const toast = useToast();
  const history = useHistory();

  async function refreshData() {
    const tokens = await fetchTokens();
    // todo: set dcc and fsh tokens
    tokens?.forEach((token) => {
      console.log('token balance', token.balance);

      let tokenBalance = parseFloat(
        token.balance.slice(0, token.balance.length - 18),
      );
      tokenBalance = Math.floor(tokenBalance * 100) / 100 || 0;

      let tokenPendingReward = parseFloat(
        web3.utils.fromWei(token.pendingReward),
      );
      tokenPendingReward = Math.floor(tokenPendingReward * 100) / 100;

      if (token.symbol === 'DCC') {
        UserStore.setDcc(tokenBalance);
      } else if (token.symbol === 'FSH') {
        UserStore.setFsh(tokenBalance);
        UserStore.setPendingReward(tokenPendingReward);
      }
    });

    // return tokens;
  }

  useEffect(() => {
    if (open) onOpen();
    else onClose();
  }, [open]);

  const setSlot = async (chessId: string, slot: ChessSlot) => {
    GameStore.setLoadingMyChesses(true);
    GameStore.setLoadingMyAllChesses(true);

    onClose();
    onCloseDialog?.();
    try {
      await patchOnUsedItem(chessId, slot);
      const myChess = (await getChessPieces(UserStore.clientId)) as any;
      const parsedChesses = parseChesses(myChess.items);
      GameStore.setMyChesses(parsedChesses);

      const myAllChess = (await getAllChessPieces(UserStore.clientId)) as any;
      const parsedAllChesses = parseChesses(myAllChess.items);
      GameStore.setMyAllChesses(parsedAllChesses);
    } catch (e) {
      toast({
        title: `${e}`,
        status: 'error',
        isClosable: true,
      });
    } finally {
      GameStore.setLoadingMyChesses(false);
      GameStore.setLoadingMyAllChesses(false);
    }
  };

  const listOnMarket = async (chessId: string, price: number) => {
    GameStore.setLoadingMyChesses(true);
    GameStore.setLoadingMyAllChesses(true);

    onClose();
    onCloseDialog?.();
    try {
      await patchListedChess(chessId, price);
      const myChess = (await getChessPieces(UserStore.clientId)) as any;
      const parsedChesses = parseChesses(myChess.items);
      GameStore.setMyChesses(parsedChesses);

      const myAllChess = (await getAllChessPieces(UserStore.clientId)) as any;
      const parsedAllChesses = parseChesses(myAllChess.items);
      GameStore.setMyAllChesses(parsedAllChesses);
      toast({
        title: `Successful listing`,
        status: 'success',
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: `${e}`,
        status: 'error',
        isClosable: true,
      });
    } finally {
      GameStore.setLoadingMyChesses(false);
      GameStore.setLoadingMyAllChesses(false);
    }
  };

  const unlistOnMarket = async (chessId: string) => {
    GameStore.setLoadingMyChesses(true);
    GameStore.setLoadingMyAllChesses(true);

    onClose();
    onCloseDialog?.();
    try {
      await patchUnlistedChess(chessId);
      const myChess = (await getChessPieces(UserStore.clientId)) as any;
      const parsedChesses = parseChesses(myChess.items);
      GameStore.setMyChesses(parsedChesses);

      const myAllChess = (await getAllChessPieces(UserStore.clientId)) as any;
      const parsedAllChesses = parseChesses(myAllChess.items);
      GameStore.setMyAllChesses(parsedAllChesses);
      toast({
        title: `Successful unlisting`,
        status: 'success',
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: `${e}`,
        status: 'error',
        isClosable: true,
      });
    } finally {
      GameStore.setLoadingMyChesses(false);
      GameStore.setLoadingMyAllChesses(false);
    }
  };

  const buyP2P = async (chessId: string) => {
    GameStore.setLoadingMyChesses(true);
    GameStore.setLoadingMyAllChesses(true);

    onClose();
    onCloseDialog?.();
    try {
      await postBuyP2P(UserStore.getClientId(), chessId);
      history.push('/manage');
      refreshData();
      toast({
        title: `Successful buying`,
        status: 'success',
        isClosable: true,
      });
      const myChess = (await getChessPieces(UserStore.clientId)) as any;
      const parsedChesses = parseChesses(myChess.items);
      GameStore.setMyChesses(parsedChesses);

      const myAllChess = (await getAllChessPieces(UserStore.clientId)) as any;
      const parsedAllChesses = parseChesses(myAllChess.items);
      GameStore.setMyAllChesses(parsedAllChesses);
    } catch (e) {
      toast({
        title: `${e}`,
        status: 'error',
        isClosable: true,
      });
    } finally {
      GameStore.setLoadingMyChesses(false);
      GameStore.setLoadingMyAllChesses(false);
    }
  };

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        {/* @ts-ignore */}
        <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
        {/* @ts-ignore */}
        <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : (
      <ButtonGroup justifyContent="center" size="sm">
        {/* @ts-ignore */}
        <IconButton icon={<EditIcon />} {...getEditButtonProps()} />
      </ButtonGroup>
    );
  }

  const onNameChange = (nextName: string) => {
    console.log('name change', nextName);
    if (chess) {
      GameStore.setChessName(chess.id, nextName);
      patchUpdateName(chess.nftId, nextName);
    }
  };

  return (
    <>
      {isNew && open && <Confetti />}
      {chess && (
        <Modal
          isCentered
          onClose={() => {
            onCloseDialog?.();
            onClose();
          }}
          isOpen={isOpen}
          motionPreset="slideInBottom"
          size="4xl"
        >
          <ModalOverlay />
          <ModalContent borderRadius="30px">
            {isNew && (
              <ModalHeader
                backgroundColor="green.100"
                borderRadius="30px 30px 0 0"
              >
                <Text fontSize="32px" fontWeight="medium" textAlign="center">
                  Congratulations, you got a new chess:
                </Text>
              </ModalHeader>
            )}
            <ModalCloseButton size="lg" mr="10px" mt="10px" />
            <ModalBody
              minHeight="500px"
              display="flex"
              flexDirection="row"
              justifyContent="start"
              alignItems="center"
            >
              <Flex width="100%">
                <Flex flexDirection="column" alignItems="center">
                  <Image src={chess.img} maxHeight="260px" />
                  <Text fontSize="24px" fontWeight="bold" alignItems="center">
                    #{chess.id}
                  </Text>
                  {chess.saleListed && !listedMode && (
                    <Tag colorScheme="red">Listed on marketplace</Tag>
                  )}
                </Flex>
                <Flex flexDirection="column" ml="60px" flexGrow={1}>
                  {/* <Text fontSize="40px" fontWeight="bold">
                    {chess.name}
                  </Text> */}
                  <Editable
                    display="flex"
                    textAlign="initial"
                    defaultValue={chess.name}
                    fontSize="2xl"
                    isPreviewFocusable={false}
                    flexDir="row"
                    gridGap="10px"
                    onSubmit={onNameChange}
                  >
                    <EditablePreview fontSize="40px" fontWeight="bold" />
                    {/* Here is the custom input */}
                    <Input as={EditableInput} maxW="200px" />
                    <EditableControls />
                  </Editable>
                  <Text fontSize="24px">HP: {chess.maxHp}</Text>
                  <Text fontSize="24px">ATK: {chess.atk}</Text>
                  <Text fontSize="24px">DEF: {chess.def}</Text>
                  <Text fontSize="24px">SPD: {chess.spd}</Text>
                  {!chess.saleListed && (
                    <Flex justifyContent="flex-end" mr="40px" mt="50px">
                      {chess.slot === ChessSlot.UNUSED &&
                        chess.chessType !== ChessClass.Bishop && (
                          <Button
                            size="lg"
                            onClick={() => {
                              setSlot(
                                chess.id.toString(),
                                chess.chessType.toString() as ChessSlot,
                              );
                            }}
                          >
                            Set slot {`${chess.chessType}`}
                          </Button>
                        )}
                      {(chess.slot === ChessSlot.UNUSED ||
                        chess.slot === ChessSlot.BISHOP_2) &&
                        chess.chessType === ChessClass.Bishop && (
                          <Button
                            size="lg"
                            onClick={() => {
                              setSlot(chess.id.toString(), ChessSlot.BISHOP_1);
                            }}
                          >
                            Set slot Bishop 1
                          </Button>
                        )}
                      {(chess.slot === ChessSlot.UNUSED ||
                        chess.slot === ChessSlot.BISHOP_1) &&
                        chess.chessType === ChessClass.Bishop && (
                          <Button
                            size="lg"
                            ml="10px"
                            onClick={() => {
                              setSlot(chess.id.toString(), ChessSlot.BISHOP_2);
                            }}
                          >
                            Set slot Bishop 2
                          </Button>
                        )}
                      <Button
                        size="lg"
                        ml="10px"
                        variant="solid"
                        backgroundColor="purple.500"
                        onClick={() => {
                          // listOnMarket(chess.id.toString());
                          setOpenListForm.toggle();
                        }}
                      >
                        List on Marketplace
                      </Button>
                    </Flex>
                  )}
                  {listedMode &&
                    UserStore.playerId !== chess.playerId &&
                    chess.saleListed && (
                      <Flex justifyContent="flex-end" mr="40px" mt="50px">
                        <Button
                          size="lg"
                          ml="10px"
                          variant="solid"
                          backgroundColor="purple.500"
                          onClick={() => {
                            setOpenConfirmBuyDialog(true);
                          }}
                        >
                          Buy with {chess.price} FSH
                        </Button>
                      </Flex>
                    )}
                  {UserStore.playerId === chess.playerId && chess.saleListed && (
                    <Flex justifyContent="flex-end" mr="40px" mt="50px">
                      <Button
                        size="lg"
                        ml="10px"
                        variant="solid"
                        backgroundColor="purple.500"
                        onClick={() => {
                          unlistOnMarket(chess.nftId);
                        }}
                      >
                        Unlist
                      </Button>
                    </Flex>
                  )}
                  <Collapse in={openListForm}>
                    <Flex mr="40px" mt="20px" gridGap="10px">
                      <Input
                        placeholder="Price"
                        size="lg"
                        value={listedPrice}
                        onChange={(event) => setListedPrice(event.target.value)}
                      />
                      <Button
                        disabled={!listedPrice || Number.isNaN(+listedPrice)}
                        size="lg"
                        onClick={() => {
                          setOpenConfirmDialog(true);
                        }}
                      >
                        List
                      </Button>
                    </Flex>
                  </Collapse>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
          <ConfirmDialog
            open={openConfirmDialog}
            title="Confirmation"
            body={`Would you like to list the chess on marketplace with the price of ${listedPrice} FSH?`}
            onYes={() => listOnMarket(chess.nftId.toString(), +listedPrice)}
            onCancel={() => setOpenConfirmDialog(false)}
          />
          <ConfirmDialog
            open={openConfirmBuyDialog}
            title="Confirmation"
            body={`Would you like to buy the chess with ${chess.price} FSH?`}
            onYes={() => {
              buyP2P(chess.nftId);
            }}
            onCancel={() => setOpenConfirmBuyDialog(false)}
          />
        </Modal>
      )}
    </>
  );
}

export default ChessInfoDialog;
